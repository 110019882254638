import React from 'react';
import _ from "lodash";
import {Card, Form, Table} from "antd";
import {getFieldType} from "../../common/schema";
import {generateDefaultValue, isInternal, tt} from "../../common/schemaUtils";
import FieldTable from "../fields/FieldTable";
import {useSchema} from "../../contexts/schema";

const Editor = ({card, form}) => {
    const {section, template} = useSchema();
    const fields = template ? _.reject(section.fields, 'hideInTemplate') : section.fields;

    const props = {form};
    props.columns = fields.map((field) => {
        const {id, type, title, fieldProps, ...rest} = field;
        const FieldType = getFieldType(field.type);
        return {
            key: id,
            dataIndex: id,
            title: tt(field),
            editable: true,
            fieldComponent: (props) => <FieldType.Editor field={field} {...props} />,
            render: (val) => <FieldType.Viewer field={field} value={val} />,
            onFormItem: fieldProps,
            onField: rest
        }
    });
    props.baseValue = generateDefaultValue(section, template);
    if (section.autofill) {
        props.onQuickAdd = async (template) => _.omit(template, (val, key) => isInternal(key));
    }

    const child = (
        <Form.Item name={section.id} valuePropName="values">
            <FieldTable inlineEditable dragAndDrop {...props} />
        </Form.Item>
    )
    if (card) return <Card>{child}</Card>;
    return child;
}

const Viewer = ({card, value}) => {
    const {section, template} = useSchema();
    const fields = template ? _.reject(section.fields, 'hideInTemplate') : section.fields;
    const columns = fields.map((field) => {
        const FieldType = getFieldType(field.type);
        return {
            key: field.id,
            dataIndex: field.id,
            title: tt(field),
            ellipsis: true,
            render: (val) => <FieldType.Viewer field={field} value={val} compact />,
        }
    });
    const child = <Table columns={columns} dataSource={value} size="small" />
    if (card) return <Card>{child}</Card>;
    return child;
}

export default {Editor, Viewer}
