import React, {useEffect, useState} from 'react';
import {Divider, Descriptions, Tooltip, Form, Input, Col, Row, Typography, Collapse, Space} from "antd";
import {ButtonGroupFormField} from "./ButtonGroupField";
import {calculateCVSS31Scores, cvssVectorMapToString, parseVector} from "../../lib/cvss";
import {InfoCircleOutlined} from '@ant-design/icons';
import {Column} from '@ant-design/charts';

const {Title} = Typography;
const {Panel} = Collapse;

const AV_CHOICES = [
    {
        label: 'Network (AV:N)',
        value: 'N',
        tooltip: "A vulnerability exploitable with Network access means the vulnerable component is bound to the network stack and the attacker's path is through OSI layer 3 (the network layer). Such a vulnerability is often termed 'remotely exploitable' and can be thought of as an attack being exploitable one or more network hops away (e.g. across layer 3 boundaries from routers)."
    },
    {
        label: 'Adjacent Network (AV:A)',
        value: 'A',
        tooltip: "A vulnerability exploitable with Adjacent Network access means the vulnerable component is bound to the network stack, however the attack is limited to the same shared physical (e.g. Bluetooth, IEEE 802.11), or logical (e.g. local IP subnet) network, and cannot be performed across an OSI layer 3 boundary (e.g. a router)."
    },
    {
        label: 'Local (AV:L)',
        value: 'L',
        tooltip: "A vulnerability exploitable with Local access means that the vulnerable component is not bound to the network stack, and the attacker's path is via read/write/execute capabilities. In some cases, the attacker may be logged in locally in order to exploit the vulnerability, or may rely on User Interaction to execute a malicious file."
    },
    {
        label: 'Physical (AV:P)',
        value: 'P',
        tooltip: "A vulnerability exploitable with Physical access requires the attacker to physically touch or manipulate the vulnerable component, such as attaching an peripheral device to a system."
    }
];

const AC_CHOICES = [
    {
        label: 'Low (AC:L)',
        value: 'L',
        tooltip: "Specialized access conditions or extenuating circumstances do not exist. An attacker can expect repeatable success against the vulnerable component."
    },
    {
        label: 'High (AC:H)',
        value: 'H',
        tooltip: "A successful attack depends on conditions beyond the attacker's control. That is, a successful attack cannot be accomplished at will, but requires the attacker to invest in some measurable amount of effort in preparation or execution against the vulnerable component before a successful attack can be expected."
    },
];

const PR_CHOICES = [
    {
        label: 'None (PR:N)',
        value: 'N',
        tooltip: "The attacker is unauthorized prior to attack, and therefore does not require any access to settings or files to carry out an attack."
    },
    {
        label: 'Low (PR:L)',
        value: 'L',
        tooltip: "The attacker is authorized with (i.e. requires) privileges that provide basic user capabilities that could normally affect only settings and files owned by a user. Alternatively, an attacker with Low privileges may have the ability to cause an impact only to non-sensitive resources."
    },
    {
        label: 'High (PR:H)',
        value: 'H',
        tooltip: "The attacker is authorized with (i.e. requires) privileges that provide significant (e.g. administrative) control over the vulnerable component that could affect component-wide settings and files."
    }
];

const UI_CHOICES = [
    {
        label: 'None (UI:N)',
        value: 'N',
        tooltip: "The vulnerable system can be exploited without interaction from any user."
    },
    {
        label: 'Required (UI:R)',
        value: 'R',
        tooltip: "Successful exploitation of this vulnerability requires a user to take some action before the vulnerability can be exploited, such as convincing a user to click a link in an email."
    }
];

const S_CHOICES = [
    {
        label: 'Unchanged (S:U)',
        value: 'U',
        tooltip: "An exploited vulnerability can only affect resources managed by the same authority. In this case the vulnerable component and the impacted component are the same."
    },
    {
        label: 'Changed (S:C)',
        value: 'C',
        tooltip: "An exploited vulnerability can affect resources beyond the authorization privileges intended by the vulnerable component. In this case the vulnerable component and the impacted component are different."
    }
];

const C_CHOICES = [
    {
        label: 'None (C:N)',
        value: 'N',
        tooltip: "There is no loss of confidentiality within the impacted component."
    },
    {
        label: 'Low (C:L)',
        value: 'L',
        tooltip: "There is some loss of confidentiality. Access to some restricted information is obtained, but the attacker does not have control over what information is obtained, or the amount or kind of loss is constrained. The information disclosure does not cause a direct, serious loss to the impacted component."
    },
    {
        label: 'High (C:H)',
        value: 'H',
        tooltip: "There is total loss of confidentiality, resulting in all resources within the impacted component being divulged to the attacker. Alternatively, access to only some restricted information is obtained, but the disclosed information presents a direct, serious impact."
    }
];

const I_CHOICES = [
    {
        label: 'None (I:N)',
        value: 'N',
        tooltip: "There is no loss of integrity within the impacted component."
    },
    {
        label: 'Low (I:L)',
        value: 'L',
        tooltip: "Modification of data is possible, but the attacker does not have control over the consequence of a modification, or the amount of modification is constrained. The data modification does not have a direct, serious impact on the impacted component."
    },
    {
        label: 'High (I:H)',
        value: 'H',
        tooltip: "There is a total loss of integrity, or a complete loss of protection. For example, the attacker is able to modify any/all files protected by the impacted component. Alternatively, only some files can be modified, but malicious modification would present a direct, serious consequence to the impacted component."
    }
];

const A_CHOICES = [
    {
        label: 'None (A:N)',
        value: 'N',
        tooltip: "There is no impact to availability within the impacted component."
    },
    {
        label: 'Low (A:L)',
        value: 'L',
        tooltip: "There is reduced performance or interruptions in resource availability. Even if repeated exploitation of the vulnerability is possible, the attacker does not have the ability to completely deny service to legitimate users. The resources in the impacted component are either partially available all of the time, or fully available only some of the time, but overall there is no direct, serious consequence to the impacted component."
    },
    {
        label: 'High (A:H)',
        value: 'H',
        tooltip: "There is total loss of availability, resulting in the attacker being able to fully deny access to resources in the impacted component; this loss is either sustained (while the attacker continues to deliver the attack) or persistent (the condition persists even after the attack has completed). Alternatively, the attacker has the ability to deny some availability, but the loss of availability presents a direct, serious consequence to the impacted component (e.g., the attacker cannot disrupt existing connections, but can prevent new connections; the attacker can repeatedly exploit a vulnerability that, in each instance of a successful attack, leaks a only small amount of memory, but after repeated exploitation causes a service to become completely unavailable)."
    }
];


const baseScoreTitle = 'Base Score Metrics';

const baseScopeDescription = `The Base metric group represents the intrinsic characteristics of a vulnerability that are constant
                    over time and across user environments. It is composed of two sets of metrics: the Exploitability
                    metrics and the Impact metrics. The Exploitability metrics reflect the ease and technical means by
                    which the vulnerability can be exploited. That is, they represent characteristics of the thing that
                    is vulnerable, which we refer to formally as the vulnerable component. On the other hand, the Impact
                    metrics reflect the direct consequence of a successful exploit, and represent the consequence to the
                    thing that suffers the impact, which we refer to formally as the impacted component.`;


const BaseScoreMetrics = () => {
    return (
        <Row gutter={[16, 16]} justify={'center'}>
            <Col lg={12} md={24}>
                <Title level={5}>Exploitability Metrics</Title>
                <ButtonGroupFormField
                    tooltip={'This metric reflects the context by which vulnerability exploitation is possible. This metric value (and consequently the Base score) will be larger the more remote (logically, and physically) an attacker can be in order to exploit the vulnerable component.'}
                    label={'Attack Vector (AV)'}
                    name={'AV'}
                    choices={AV_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'The Attack Complexity metric describes the conditions beyond the attacker\'s control that must exist in order to exploit the vulnerability. As described below, such conditions may require the collection of more information about the target, the presence of certain system configuration settings, or computational exceptions.'}
                    label={'Attack Complexity (AC)'}
                    name={'AC'}
                    choices={AC_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'This metric describes the level of privileges an attacker must possess before successfully exploiting the vulnerability.'}
                    label={'Privileges Required (PR)'}
                    name={'PR'}
                    choices={PR_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'This metric captures the requirement for a user, other than the attacker, to participate in the successful compromise of the vulnerable component. This metric determines whether the vulnerability can be exploited solely at the will of the attacker, or whether a separate user (or user-initiated process) must participate in some manner.'}
                    label={'User Interaction (UI)'}
                    name={'UI'}
                    choices={UI_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'An important property captured by CVSS v3.0 is the ability for a vulnerability in one software component to impact resources beyond its means, or privileges. This consequence is represented by the metric Authorization Scope, or simply Scope.  For more information see the CVSSv3 Specification (https://www.first.org/cvss/specification-document#i2.2).'}
                    label={'Scope (S)'}
                    name={'S'}
                    choices={S_CHOICES}
                />
            </Col>
            <Col lg={12} md={24}>
                <Title level={5}>Impact Metrics</Title>
                <ButtonGroupFormField
                    tooltip={'This metric measures the impact to the confidentiality of the information resources managed by a software component due to a successfully exploited vulnerability. Confidentiality refers to limiting information access and disclosure to only authorized users, as well as preventing access by, or disclosure to, unauthorized ones.'}
                    label={'Confidentiality Impact (C)'}
                    name={'C'}
                    choices={C_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'This metric measures the impact to integrity of a successfully exploited vulnerability. Integrity refers to the trustworthiness and veracity of information.'}
                    label={'Integrity Impact (I)'}
                    name={'I'}
                    choices={I_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'This metric measures the impact to the availability of the impacted component resulting from a successfully exploited vulnerability. While the Confidentiality and Integrity impact metrics apply to the loss of confidentiality or integrity of data (e.g., information, files) used by the impacted component, this metric refers to the loss of availability of the impacted component itself, such as a networked service (e.g., web, database, email). Since availability refers to the accessibility of information resources, attacks that consume network bandwidth, processor cycles, or disk space all impact the availability of an impacted component.'}
                    label={'Availability Impact (A)'}
                    name={'A'}
                    choices={A_CHOICES}
                />
            </Col>
        </Row>
    );
}

const E_CHOICES = [
    {
        label: 'Not Defined (E:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to a scoring equation to skip this metric."
    },
    {
        label: 'Unproven that exploit exists (E:U)',
        value: 'U',
        tooltip: "No exploit code is available, or an exploit is entirely theoretical."
    },
    {
        label: 'Proof of concept code (E:P)',
        value: 'P',
        tooltip: "Proof-of-concept exploit code is available, or an attack demonstration is not practical for most systems. The code or technique is not functional in all situations and may require substantial modification by a skilled attacker."
    },
    {
        label: 'Functional exploit exists (E:F)',
        value: 'F',
        tooltip: "Functional exploit code is available. The code works in most situations where the vulnerability exists."
    },
    {
        label: 'High (E:H)',
        value: 'H',
        tooltip: "Functional autonomous code exists, or no exploit is required (manual trigger) and details are widely available. Exploit code works in every situation, or is actively being delivered via an autonomous agent (such as a worm or virus). Network-connected systems are likely to encounter scanning or exploitation attempts. Exploit development has reached the level of reliable, widely-available, easy-to-use automated tools."
    }
];

const RL_CHOICES = [
    {
        label: 'Not Defined (RL:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to a scoring equation to skip this metric."
    },
    {
        label: 'Official Fix (RL:O)',
        value: 'O',
        tooltip: "A complete vendor solution is available. Either the vendor has issued an official patch, or an upgrade is available."
    },
    {
        label: 'Temporary Fix (RL:T)',
        value: 'T',
        tooltip: "There is an official but temporary fix available. This includes instances where the vendor issues a temporary hotfix, tool, or workaround."
    },
    {
        label: 'Workaround (RL:W)',
        value: 'W',
        tooltip: "There is an unofficial, non-vendor solution available. In some cases, users of the affected technology will create a patch of their own or provide steps to work around or otherwise mitigate the vulnerability."
    },
    {
        label: 'Unavailable (RL:U)',
        value: 'U',
        tooltip: "There is either no solution available or it is impossible to apply."
    }
];

const RC_CHOICES = [
    {
        label: 'Not Defined (RC:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to the equation to skip this metric."
    },
    {
        label: 'Unknown (RC:U)',
        value: 'U',
        tooltip: "There are reports of impacts that indicate a vulnerability is present. The reports indicate that the cause of the vulnerability is unknown, or reports may differ on the cause or impacts of the vulnerability. Reporters are uncertain of the true nature of the vulnerability, and there is little confidence in the validity of the reports or whether a static Base score can be applied given the differences described."
    },
    {
        label: 'Reasonable (RC:R)',
        value: 'R',
        tooltip: "Significant details are published, but researchers either do not have full confidence in the root cause, or do not have access to source code to fully confirm all of the interactions that may lead to the result. Reasonable confidence exists, however, that the bug is reproducible and at least one impact is able to be verified (proof-of- concept exploits may provide this)."
    },
    {
        label: 'Confirmed (RC:C)',
        value: 'C',
        tooltip: "Detailed reports exist, or functional reproduction is possible (functional exploits may provide this). Source code is available to independently verify the assertions of the research, or the author or vendor of the affected code has confirmed the presence of the vulnerability."
    }
];


const temporalScoreTitle = 'Temporal Score Metrics'

const temporalScoreDescription = `The Temporal metrics measure the current state of exploit techniques or code availability, the
                    existence of any patches or workarounds, or the confidence that one has in the description of a
                    vulnerability. Temporal metrics will almost certainly change over time.`

const TemporalScoreMetrics = () => {
    return (
        <>
            <ButtonGroupFormField
                tooltip={'This metric measures the likelihood of the vulnerability being attacked, and is typically based on the current state of exploit techniques, exploit code availability, or active, \'in-the-wild\' exploitation. The more easily a vulnerability can be exploited, the higher the vulnerability score.'}
                label={'Exploitability (E)'}
                name={'E'}
                choices={E_CHOICES}
            />
            <ButtonGroupFormField
                tooltip={'The Remediation Level of a vulnerability is an important factor for prioritization. The typical vulnerability is unpatched when initially published. Workarounds or hotfixes may offer interim remediation until an official patch or upgrade is issued. Each of these respective stages adjusts the temporal score downwards, reflecting the decreasing urgency as remediation becomes final.'}
                label={'Remediation Level (RL)'}
                name={'RL'}
                choices={RL_CHOICES}
            />
            <ButtonGroupFormField
                tooltip={'This metric measures the degree of confidence in the existence of the vulnerability and the credibility of the known technical details. Sometimes only the existence of vulnerabilities are publicized, but without specific details. The vulnerability may later be corroborated by research which suggests where the vulnerability may lie, though the research may not be certain. Finally, a vulnerability may be confirmed through acknowledgement by the author or vendor of the affected technology.'}
                label={'Report Confidence (RC)'}
                name={'RC'}
                choices={RC_CHOICES}
            />
        </>
    );
}

const MAV_CHOICES = [
    {
        label: 'Not Defined (MAV:X)',
        value: 'X',
        tooltip: "Modified Attack Vector not defined."
    },
    {
        label: 'Network (MAV:N)',
        value: 'N',
        tooltip: "Modified: A vulnerability exploitable with Network access means the vulnerable component is bound to the network stack and the attacker's path is through OSI layer 3 (the network layer). Such a vulnerability is often termed 'remotely exploitable' and can be thought of as an attack being exploitable one or more network hops away (e.g. across layer 3 boundaries from routers)."
    },
    {
        label: 'Adjacent Network (MAV:A)',
        value: 'A',
        tooltip: "Modified: A vulnerability exploitable with Adjacent Network access means the vulnerable component is bound to the network stack, however the attack is limited to the same shared physical (e.g. Bluetooth, IEEE 802.11), or logical (e.g. local IP subnet) network, and cannot be performed across an OSI layer 3 boundary (e.g. a router)."
    },
    {
        label: 'Local (MAV:L)',
        value: 'L',
        tooltip: "Modified: A vulnerability exploitable with Local access means that the vulnerable component is not bound to the network stack, and the attacker's path is via read/write/execute capabilities. In some cases, the attacker may be logged in locally in order to exploit the vulnerability, or may rely on User Interaction to execute a malicious file."
    },
    {
        label: 'Physical (MAV:P)',
        value: 'P',
        tooltip: "Modified: A vulnerability exploitable with Physical access requires the attacker to physically touch or manipulate the vulnerable component, such as attaching an peripheral device to a system."
    }
];

const MAC_CHOICES = [
    {
        label: 'Not Defined (MAC:X)',
        value: 'X',
        tooltip: "Modified Access Complexity not defined."
    },
    {
        label: 'Low (MAC:L)',
        value: 'L',
        tooltip: "Modified: Specialized access conditions or extenuating circumstances do not exist. An attacker can expect repeatable success against the vulnerable component."
    },
    {
        label: 'High (MAC:H)',
        value: 'H',
        tooltip: "Modified: A successful attack depends on conditions beyond the attacker's control. That is, a successful attack cannot be accomplished at will, but requires the attacker to invest in some measurable amount of effort in preparation or execution against the vulnerable component before a successful attack can be expected."
    },
];

const MPR_CHOICES = [
    {
        label: 'Not Defined (MPR:X)',
        value: 'X',
        tooltip: "Modified Privileges Required not defined."
    },
    {
        label: 'None (MPR:N)',
        value: 'N',
        tooltip: "Modified: The attacker is unauthorized prior to attack, and therefore does not require any access to settings or files to carry out an attack."
    },
    {
        label: 'Low (MPR:L)',
        value: 'L',
        tooltip: "Modified: The attacker is authorized with (i.e. requires) privileges that provide basic user capabilities that could normally affect only settings and files owned by a user. Alternatively, an attacker with Low privileges may have the ability to cause an impact only to non-sensitive resources."
    },
    {
        label: 'High (MPR:H)',
        value: 'H',
        tooltip: "Modified: The attacker is authorized with (i.e. requires) privileges that provide significant (e.g. administrative) control over the vulnerable component that could affect component-wide settings and files."
    }
];

const MUI_CHOICES = [
    {
        label: 'Not Defined (MUI:X)',
        value: 'X',
        tooltip: "Modified User Interaction not defined."
    },
    {
        label: 'None (MUI:N)',
        value: 'N',
        tooltip: "Modified: The vulnerable system can be exploited without interaction from any user."
    },
    {
        label: 'Required (MUI:R)',
        value: 'R',
        tooltip: "Modified: Successful exploitation of this vulnerability requires a user to take some action before the vulnerability can be exploited, such as convincing a user to click a link in an email."
    }
];

const MS_CHOICES = [
    {
        label: 'Not Defined (MS:X)',
        value: 'X',
        tooltip: "Modified Scope not defined."
    },
    {
        label: 'Unchanged (MS:U)',
        value: 'U',
        tooltip: "Modified: An exploited vulnerability can only affect resources managed by the same authority. In this case the vulnerable component and the impacted component are the same."
    },
    {
        label: 'Changed (MS:C)',
        value: 'C',
        tooltip: "Modified: An exploited vulnerability can affect resources beyond the authorization privileges intended by the vulnerable component. In this case the vulnerable component and the impacted component are different."
    }
];

const MC_CHOICES = [
    {
        label: 'Not Defined (MC:X)',
        value: 'X',
        tooltip: "Modified Confidentiality Impact not defined."
    },
    {
        label: 'None (MC:N)',
        value: 'N',
        tooltip: "Modified: There is no loss of confidentiality within the impacted component."
    },
    {
        label: 'Low (MC:L)',
        value: 'L',
        tooltip: "Modified: There is some loss of confidentiality. Access to some restricted information is obtained, but the attacker does not have control over what information is obtained, or the amount or kind of loss is constrained. The information disclosure does not cause a direct, serious loss to the impacted component."
    },
    {
        label: 'High (MC:H)',
        value: 'H',
        tooltip: "Modified: There is total loss of confidentiality, resulting in all resources within the impacted component being divulged to the attacker. Alternatively, access to only some restricted information is obtained, but the disclosed information presents a direct, serious impact."
    }
];

const MI_CHOICES = [
    {
        label: 'Not Defined (MI:X)',
        value: 'X',
        tooltip: "Modified Integrity Impact not defined."
    },
    {
        label: 'None (MI:N)',
        value: 'N',
        tooltip: "Modified: There is no loss of integrity within the impacted component."
    },
    {
        label: 'Low (MI:L)',
        value: 'L',
        tooltip: "Modified: Modification of data is possible, but the attacker does not have control over the consequence of a modification, or the amount of modification is constrained. The data modification does not have a direct, serious impact on the impacted component."
    },
    {
        label: 'High (MI:H)',
        value: 'H',
        tooltip: "Modified: There is a total loss of integrity, or a complete loss of protection. For example, the attacker is able to modify any/all files protected by the impacted component. Alternatively, only some files can be modified, but malicious modification would present a direct, serious consequence to the impacted component."
    }
];

const MA_CHOICES = [
    {
        label: 'Not Defined (MA:X)',
        value: 'X',
        tooltip: "Modified Availability Impact not defined."
    },
    {
        label: 'None (MA:N)',
        value: 'N',
        tooltip: "Modified: There is no impact to availability within the impacted component."
    },
    {
        label: 'Low (MA:L)',
        value: 'L',
        tooltip: "Modified: There is reduced performance or interruptions in resource availability. Even if repeated exploitation of the vulnerability is possible, the attacker does not have the ability to completely deny service to legitimate users. The resources in the impacted component are either partially available all of the time, or fully available only some of the time, but overall there is no direct, serious consequence to the impacted component."
    },
    {
        label: 'High (MA:H)',
        value: 'H',
        tooltip: "Modified: There is total loss of availability, resulting in the attacker being able to fully deny access to resources in the impacted component; this loss is either sustained (while the attacker continues to deliver the attack) or persistent (the condition persists even after the attack has completed). Alternatively, the attacker has the ability to deny some availability, but the loss of availability presents a direct, serious consequence to the impacted component (e.g., the attacker cannot disrupt existing connections, but can prevent new connections; the attacker can repeatedly exploit a vulnerability that, in each instance of a successful attack, leaks a only small amount of memory, but after repeated exploitation causes a service to become completely unavailable)."
    }
];

const CR_CHOICES = [
    {
        label: 'Not Defined (CR:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to the equation to skip this metric."
    },
    {
        label: 'Low (CR:L)',
        value: 'L',
        tooltip: "Loss of Confidentiality is likely to have only a limited adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'Medium (CR:M)',
        value: 'M',
        tooltip: "Loss of Confidentiality is likely to have a serious adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'High (CR:H)',
        value: 'H',
        tooltip: "Loss of Confidentiality is likely to have a catastrophic adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    }
];

const IR_CHOICES = [
    {
        label: 'Not Defined (IR:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to the equation to skip this metric."
    },
    {
        label: 'Low (IR:L)',
        value: 'L',
        tooltip: "Loss of Integrity is likely to have only a limited adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'Medium (IR:M)',
        value: 'M',
        tooltip: "Loss of Integrity is likely to have a serious adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'High (IR:H)',
        value: 'H',
        tooltip: "Loss of Integrity is likely to have a catastrophic adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    }
];

const AR_CHOICES = [
    {
        label: 'Not Defined (AR:X)',
        value: 'X',
        tooltip: "Assigning this value to the metric will not influence the score. It is a signal to the equation to skip this metric."
    },
    {
        label: 'Low (AR:L)',
        value: 'L',
        tooltip: "Loss of availability is likely to have only a limited adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'Medium (AR:M)',
        value: 'M',
        tooltip: "Loss of availability is likely to have a serious adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    },
    {
        label: 'High (AR:H)',
        value: 'H',
        tooltip: "Loss of availability is likely to have a catastrophic adverse effect on the organization or individuals associated with the organization (e.g., employees, customers)."
    }
];

const environmentalScoreMetrics = `These metrics enable the analyst to customize the CVSS score depending on the importance
                    of the affected IT asset to a user's organization, measured in terms of complementary/alternative
                    security controls in place, Confidentiality, Integrity, and Availability. The metrics are the
                    modified equivalent of base metrics and are assigned metrics value based on the component placement
                    in organization infrastructure.`;

const environmentalScoreTitle = 'Environmental Score Metrics';

const EnvironmentalScoreMetrics = () => {
    return (
        <Row>
            <Col md={24} lg={13}>
                <Title level={5}>Base Modifiers</Title>
                <ButtonGroupFormField
                    tooltip={'Used to modify the base attack vector settings.'}
                    label={'Attack Vector (MAV)'}
                    name={'MAV'}
                    choices={MAV_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base access complexity settings.'}
                    label={'Attack Complexity (MAC)'}
                    name={'MAC'}
                    choices={MAC_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base privileges required settings.'}
                    label={'Privileges Required (MPR)'}
                    name={'MPR'}
                    choices={MPR_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base user interaction settings.'}
                    label={'User Interaction (MUI)'}
                    name={'MUI'}
                    choices={MUI_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base scope settings.'}
                    label={'Scope (MS)'}
                    name={'MS'}
                    choices={MS_CHOICES}
                />
            </Col>
            <Col>
                <Title level={5}>Impact Metrics</Title>
                <ButtonGroupFormField
                    tooltip={'Used to modify the base confidentiality requirement settings.'}
                    label={'Confidentiality Impact (MC)'}
                    name={'MC'}
                    choices={MC_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base integrity impact settings.'}
                    label={'Integrity Impact (MI)'}
                    name={'MI'}
                    choices={MI_CHOICES}
                />
                <ButtonGroupFormField
                    tooltip={'Used to modify the base availability impact settings.'}
                    label={'Availability Impact (MA)'}
                    name={'MA'}
                    choices={MA_CHOICES}
                />
                <Title level={5}>Impact Subscore Modifiers</Title>
                <ButtonGroupFormField
                    label={'Confidentiality Requirement (CR)'}
                    name={'CR'}
                    choices={CR_CHOICES}
                />
                <ButtonGroupFormField
                    label={'Integrity Requirement (IR)'}
                    name={'IR'}
                    choices={IR_CHOICES}
                />
                <ButtonGroupFormField
                    label={'Availability Requirement (AR)'}
                    name={'AR'}
                    choices={AR_CHOICES}
                />
            </Col>
        </Row>
    );
}

const initialState = {
    AV: '',
    AC: '',
    PR: '',
    UI: '',
    S: '',
    C: '',
    I: '',
    A: '',
    E: '',
    RL: '',
    RC: '',
    MAV: '',
    MAC: '',
    MPR: '',
    MUI: '',
    MS: '',
    MC: '',
    MI: '',
    MA: '',
    CR: '',
    IR: '',
    AR: ''
}

const chartConfig = {
    title: {
        visible: true,
        text: 'Base Scores',
    },
    description: {
        visible: false
    },
    forceFit: true,
    padding: 'auto',
    xField: 'type',
    color: 'l(90) 1:#228c22 0:#e12120',
    yField: 'score',
    yAxis: {
        max: 10
    },
    label: {
        visible: true,
        position: 'middle',
        style: {
            fill: '#fff',
            fontSize: 12
        },
    },
};

const BaseScoreChart = ({score: {base, impact, exploitability}}) => {
    const data = [
        {
            type: 'Base',
            score: base || 0,
        },
        {
            type: 'Impact',
            score: impact || 0,
        },
        {
            type: 'Exploitability',
            score: exploitability || 0,
        }
    ];


    return <Column data={data} {...chartConfig} />;
};


const TemporalScoreChart = ({score}) => {
    const data = [
        {
            type: 'Temporal',
            score: score || 0,
        }
    ];

    return <Column data={data} {...chartConfig}/>;
};


const EnvironmentalScoreChart = ({score: {environmental, impact}}) => {
    const data = [
        {
            type: 'Environmental',
            score: environmental || 0,
        },
        {
            type: 'Modified Impact',
            score: impact || 0,
        }
    ];

    return <Column data={data} {...chartConfig}/>;
};


const OverallScoreChart = ({score}) => {

    const data = [
        {
            type: 'Overall',
            score: score || 0,
        }
    ];

    return <Column data={data} {...chartConfig}/>;
}


const SummaryChart = ({score, showEnvironmental = false}) => {

    const data = [
        {
            type: 'Base',
            score: score?.base?.base || 0,
        },
        {
            type: 'Temporal',
            score: score?.temporal || 0,
        }
    ];

    if (showEnvironmental) {
        data.push({
            type: 'Environmental',
            score: score?.environmental?.environmental || 0,
        });
    }

    data.push({
        type: 'Overall',
        score: score?.overall || 0,
    });

    return <Column data={data} {...chartConfig}/>;
}


const ScoreSummary = ({score: {base, temporal, environmental, overall, showEnvironmental = false}}) => {
    return (
        <Descriptions column={1} size={'small'}>
            <Descriptions.Item label={<strong>CVSS Base Score</strong>}>{base.base || 0}</Descriptions.Item>
            <Descriptions.Item label="Impact Subscore">{base.impact || 0}</Descriptions.Item>
            <Descriptions.Item label="Exploitability Subscore">{base.exploitability || 0}</Descriptions.Item>
            <Descriptions.Item label={<strong>CVSS Temporal Score</strong>}>{temporal || 0}</Descriptions.Item>
            {showEnvironmental &&
            <Descriptions.Item
                label="CVSS Environmental Score">{environmental.environmental || 0}</Descriptions.Item>
            }
            {showEnvironmental &&
                <Descriptions.Item label="Modified Impact Subscore">{environmental.impact || 0}</Descriptions.Item>
            }
            <Descriptions.Item label={<strong>Overall CVSS Score</strong>}>{overall || 0}</Descriptions.Item>
        </Descriptions>
    );
}


export const CVSSCalculator = (
    {
        form,
        values = initialState,
        showEnvironmental = false,
        onCancel = console.log,
        onSubmit = console.log
    }
) => {
    const [score, setScore] = useState(() => {
        const scores = calculateCVSS31Scores(values, !showEnvironmental);
        form.setFieldsValue({scores});
        return scores;
    });

    const {base, temporal, environmental, overall} = score;

    useEffect(() => {
        let vector = values;
        let vectorMap = values;
        if (typeof values === 'string') {
            vectorMap = parseVector(values, !showEnvironmental);
        } else {
            vector = cvssVectorMapToString(values, !showEnvironmental);
        }
        form.setFieldsValue({...vectorMap, vector});
        console.log(values, vector, vectorMap);
    }, [form, values, showEnvironmental])

    const handleVectorChange = ({target: {value}}) => {
        if (value === '') {
            form.resetFields();
            setScore(calculateCVSS31Scores({}, !showEnvironmental));
        } else {
            const scores = calculateCVSS31Scores(value, !showEnvironmental);
            const vectorMap = parseVector(value, !showEnvironmental);
            const vector = cvssVectorMapToString(vectorMap, !showEnvironmental);
            setScore(scores);
            form.setFieldsValue({...vectorMap, vector, scores});
        }
    };

    const handleFormChange = (change, values) => {
        if (!change.hasOwnProperty('vector')) {
            const scores = calculateCVSS31Scores(values, !showEnvironmental);
            setScore(scores);
            form.setFieldsValue({vector: cvssVectorMapToString(values, !showEnvironmental), scores, ...change});
        }
    }

    return (
        <>
            <Row gutter={[32]} justify={'center'}>
                <Col xxl={5} xl={6} lg={0} md={0} sm={0} xs={0}>
                    <BaseScoreChart score={base}/>
                </Col>
                <Col xxl={2} xl={3} lg={0} md={0} sm={0} xs={0}>
                    <TemporalScoreChart score={temporal}/>
                </Col>
                {showEnvironmental &&
                <Col xxl={4} xl={5} lg={0} md={0} sm={0} xs={0}>
                    <EnvironmentalScoreChart score={environmental}/>
                </Col>
                }
                <Col xxl={2} xl={3} lg={0} md={0} sm={0} xs={0}>
                    <OverallScoreChart score={overall}/>
                </Col>
                <Col xxl={0} xl={0} lg={17} md={17} sm={17} xs={17}>
                    <SummaryChart score={score} showEnvironmental={showEnvironmental}/>
                </Col>
                <Col xxl={4} xl={5} lg={7} md={7} sm={7} xs={7}>
                    <ScoreSummary score={score} showEnvironmental={showEnvironmental}/>
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={24}>
                    <Form
                        onValuesChange={handleFormChange}
                        onFinish={onSubmit}
                        layout={'vertical'}
                        size={'small'}
                        form={form}
                    >
                        <Form.Item
                            name={'vector'}
                        >
                            <Input onChange={handleVectorChange} placeholder={'CVSS Vector'}/>
                        </Form.Item>
                        <Collapse accordion>
                            <Panel header={
                                <Space>
                                    {baseScoreTitle}
                                    <Tooltip title={baseScopeDescription} mouseEnterDelay={1}>
                                        <InfoCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            } key='1'>
                                <BaseScoreMetrics/>
                            </Panel>
                            <Panel header={
                                <Space>
                                    {temporalScoreTitle}
                                    <Tooltip title={temporalScoreDescription} mouseEnterDelay={1}>
                                        <InfoCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            } key='2'>
                                <TemporalScoreMetrics/>
                            </Panel>
                            {showEnvironmental &&
                            <Panel header={
                                <Space>
                                    {environmentalScoreTitle}
                                    <Tooltip title={environmentalScoreMetrics} mouseEnterDelay={1}>
                                        <InfoCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            } key='3'>
                                <EnvironmentalScoreMetrics/>
                            </Panel>
                            }
                        </Collapse>
                    </Form>
                </Col>
            </Row>
        </>
    );
};