export const STATUSES = {
    project: {
        incomplete: {name: 'Incomplete', value: 'incomplete', color: '#B22222'},
        progress: {name: 'In Progress', value: 'progress', color: '#FF8C00'},
        complete: {name: 'Complete', value: 'complete', color: '#228B22'}
    }
}

export const calculateAggregate = ({probabilityOfCompromise, outcomeSeverity}) => {
    return (probabilityOfCompromise || 1) + (outcomeSeverity || 1) - 1;
}
