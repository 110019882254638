import update from "immutability-helper";
import React from "react";
import InfiniteTable from "../elements/InfiniteTable";

/**
 * HOC wrapper on {@link InfiniteTable} for using controlled data.
 * Exposes `values` and `onChange` for usage inside {@link Form.Item}
 */
const FieldTable = ({values, searchFields, onChange, ...baseProps}) => {
    const applyFilter = (filter) => (value) => {
        for (const field of searchFields) {
            if (filter.test(value[field])) return true;
        }
        return false;
    }

    const handleDragAndDrop = async (dragIndex, hoverIndex) => {
        const dragRow = values[dragIndex];
        const next = update(values, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        });
        onChange(next);
        return next;
    }

    const handleSave = async ({key, value}) => {
        const next = [...values];
        next[key] = {...next[key], ...value};
        onChange(next);
        return next[key];
    }

    const handleDelete = async (deleteValues, deleteKeys) => {
        const next = values.filter((value, i) => !deleteKeys.includes(i));
        onChange(next);
        return true;
    }

    return <InfiniteTable
        data={values}
        onFilter={applyFilter}
        onSave={handleSave}
        onDragAndDrop={handleDragAndDrop}
        onDelete={handleDelete}
        rowNumbering="automatic"
        {...baseProps}
    />
}

export default FieldTable;
