import React, {useEffect, useMemo, useState} from 'react';
import _ from "lodash";
import {AutoComplete, Button, message, notification} from "antd";
import {useAutofillFunctions} from "../../contexts/schema";
import {isInternal} from "../../common/schemaUtils";

const Autofill = ({form = null, onTemplate = _.noop, field, value: initialValue, onChange, ...props}) => {
    const [value, setValue] = useState('');
    useEffect(() => setValue(initialValue), [initialValue]);
    const [options, setOptions] = useState([]);
    const searchFunctions = useAutofillFunctions();

    const handleTemplateSelected = (template) => {
        if (field.confirm && form?.isFieldsTouched()) {
            // TODO: merge option (concat fields? or maybe only overwrite untouched)
            const key = `notify${Date.now()}`;
            const handleConfirm = () => {
                notification.close(key);
                applyTemplate(template);
            }
            notification.warn({
                message: 'Overwrite form content?',
                description: 'Using a template will overwrite all existing fields.',
                btn: <Button type="primary" onClick={handleConfirm}>Confirm</Button>,
                key
            })
        } else {
            applyTemplate(template);
        }
    }

    const applyTemplate = (template) => {
        setValue(template[field.id] ?? '');
        if (form) form.setFieldsValue(_.omitBy(template, (val, key) => isInternal(key)));
        if (onTemplate) onTemplate(template);
    }

    const handleQuery = useMemo(() => _.debounce((search) => {
        searchFunctions.query(search)
            .then((options) => setOptions(options))
            .catch(console.error);
    }, 500), []);

    const handleChange = (search) => {
        setValue(search);
        handleQuery(search);
        onChange(search);
    }

    const handleSelect = (value) => {
        searchFunctions.select(value)
            .then((template) => handleTemplateSelected(template))
            .catch((e) => {
                console.error(e);
                message.error('Could not fill fields');
            })
    }

    useEffect(() => {
        handleQuery('');
    }, []);

    return (
        <AutoComplete
            options={options}
            onSelect={handleSelect}
            value={value}
            onChange={handleChange}
            {...props}
        />
    );
}

export default {Editor: Autofill, Viewer: ({value}) => <>{value}</>, defaultValue: ''};
