import React from "react";
import {config} from '../config';
import {MsalProvider, MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";

const authClient = new PublicClientApplication({
    auth: {
        authority: config.authority,
        clientId: config.clientId,
        redirectUri: config.redirectUri
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
});

const ErrorComponent = ({error}) => <p>An Error Occurred: {error}</p>;
const LoadingComponent = () => <p>Loading...</p>;

export const AuthProvider = ({children}) => (
    <MsalProvider instance={authClient}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{scopes: config.scopes}}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            {children}
        </MsalAuthenticationTemplate>
    </MsalProvider>
);