import { Select } from 'antd';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import LocaleList from "../../lib/LocaleList";
import moment from "moment";
const { Option } = Select;

/**
 * Modify the current locale. To add locales, add to `LocaleList`.
 */
export default () => {
    const { t, i18n } = useTranslation("navigation");
    const [language, setLanguage] = useState(i18n.language);
    
    return (
        <Select
            showSearch
            style={{ width: '10em' }}
            optionFilterProp="children"
            onChange={(e) => {
                setLanguage(e);
                i18n.changeLanguage(e);
                moment.locale(e);
            }}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={language}
        >
            {LocaleList.map(({code, name})=> {
                const lang = t(`locales.${code}`);
                return (
                    <Option key={code} value={code}>
                        {language === code ? lang : `${lang} (${name})`}
                    </Option>
                )
            })}
        </Select>
    );
}
