export const config = {
    authority: process.env.REACT_APP_OAUTH_AUTHORITY,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
    reportingServerEndpoint: process.env.REACT_APP_REGENX_REPORTING_ENDPOINT_URL ?? '/report/generate',
    couchDBEndpoint: process.env.REACT_APP_REGENX_COUCH_DB_ENDPOINT_URL ?? '/regenx1',
    scopes: [
        // 'user.read',
        `${process.env.REACT_APP_OAUTH_CLIENT_ID}/.default`
    ]
};
