import React, {useEffect, useRef, useState} from 'react';
import {Input, Select, SelectProps, Tag} from 'antd';
import {FieldEditor, FieldType, FieldViewer} from "../../common/schema";
import {useDB} from "../../contexts/database";
import {format} from "../../lib/string-template";
import DBInterface from "../../common/dbInterface";
import _ from "lodash";

interface FP extends SelectProps<any> {
    // type name to lookup for relation
    relationType: string;
    // template to create titles (e.g. "{{short}} - {{long}}")
    optionTitle: string;
    // template to sort by (e.g. "{{id}}")
    optionSort: string;
}

const project = Symbol('link$parent');
type VT = string[] & {[project]?: string};

// TODO: global cache is hella ugly, probably better to just do partial replication or something
const relationCache = Symbol('relationCache');
declare global {
    interface Window {
        db: DBInterface;
        // db _id => formatted title. use promises to minimize number of requests
        [relationCache]: Map<string, Promise<string>>;
    }
}
window[relationCache] = new Map();

// load options based on given field schema and parent project
const useOptions = (projectId: string, field: FieldSchema<VT, FP>) => {
    const db = useDB();
    const docs = useRef<Map<string, DBObject>>(new Map());
    const [options, setOptions] = useState<AntOption[] | null>(null);
    useEffect(() => {
        const startkey = projectId + '/' + field.relationType;
        db.query('map/by_project_and_type', {startkey, endkey: startkey + '\ufff0', include_docs: true})
            .then((res) => {
                const rows = res.rows.map(({doc}) => doc) as DBObject[];
                setOptions(rows.map((doc) => {
                    docs.current.set(doc._id, doc);
                    return {value: doc._id, label: format(field.optionTitle, doc)};
                }));
            });
    }, []);
    return {docs, options};
}

const RelationEditor: FieldEditor<VT, FP> = ({field, value: initialValue, onChange, form, ...rest}) => {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const projectId = form.getFieldsValue(['link$project']).link$project;
    const {docs, options} = useOptions(projectId, field);
    if (!options) return <Input disabled value="Loading..." />;

    const props = {
        value, onChange, options,
        mode: 'multiple',
        optionFilterProp: 'label',
        ...rest,
    } as SelectProps<string[]>;

    if (field.optionSort) {
        props.filterSort = ({value: a}, {value: b}): number => {
            return format(field.optionSort, docs.current.get(a)).toLocaleLowerCase()
                .localeCompare(format(field.optionSort, docs.current.get(b)).toLocaleLowerCase());
        }
    }

    return <Select {...props} />;
}

const RelationViewer: FieldViewer<VT, FP> = ({field, value}) => {
    const db = useDB();
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        const toFetch = value.filter((id) => !window[relationCache].has(id));
        if (toFetch.length > 0) {
            const req = db.allDocs({include_docs: true, keys: toFetch});
            toFetch.forEach((id) => {
                window[relationCache].set(id, req.then((res) => {
                    const doc = _.find(res.rows, ['doc._id', id])?.doc;
                    return format(field.optionTitle, doc);
                }));
            });
        }
        Promise.all(value.map((id) => window[relationCache].get(id) as Promise<string>))
            .then((labels) => setLabels(labels));
    }, [value]);

    if (value.length === 0) return <em>None</em>;
    return <>{labels.map((label) => <Tag key={label}>{label}</Tag>)}</>;
}

export default {
    Editor: RelationEditor,
    Viewer: RelationViewer,
    defaultValue: [],
    afterGet(doc, field) {
        // use a symbol property to the array value, which will not affect stringify or db writing
        doc[field.id][project] = doc.link$project;
    },
    async stringify(value) {
        return value;
    }
} as FieldType<VT, FP>;
