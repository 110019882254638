import React from "react";
import {DatePicker} from "antd";
import moment from "moment";
import {useTranslation} from "react-i18next";

export default {
    Editor: ({field, ...props}) => <DatePicker.RangePicker style={{width: '100%'}} {...field} {...props} />,
    Viewer: ({value, compact = false}) => {
        const {i18n} = useTranslation();
        const f = (v) => moment(v).locale(i18n.language).format('lll');
        return compact ? <>{f(value[1])}</> : <>{f(value[0])}&ndash;{f(value[1])}</>;
    },
    defaultValue: [moment(), moment()],
    stringify: async (value) => [value[0].toDate(), value[1].toDate()],
    beforePut: (doc, field) => doc[field.id] = [doc[field.id][0].toDate(), doc[field.id][1].toDate()],
    afterGet: (doc, field) => doc[field.id] = [moment(doc[field.id][0]), moment(doc[field.id][1])]
}
