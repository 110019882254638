import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next } from "react-i18next";
import _ from 'lodash';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            prefix: '%{',
            suffix: '}',
            format: function(value, format, lng) {
                if (format === 'title') return _.startCase(value);
                if (format === 'sentence') return _.upperFirst(value);
                return value;
            }
        },
        backend: {
            loadPath: '/locales/%{lng}/%{ns}.json'
        }
    });

export default i18n;