import React from 'react';
import _ from "lodash";
import {Checkbox, Col, Row} from "antd";
import {tt} from "../../common/schemaUtils";

const Editor = ({field, value, onChange}) => {
    const {options} = field;
    const checkAll = options.length === value.length;
    const indeterminate = value.length > 0 && options.length > value.length;
    const onCheckAllChange = () => {
        if (checkAll) onChange([]);
        else onChange(options.map((option) => option.value));
    }

    return (
        <>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                <strong>Select all</strong>
            </Checkbox>
            <Checkbox.Group onChange={onChange} value={value}>
                <Row>
                    {options.map((option) => (
                        <Col span={24} key={option.value}>
                            <Checkbox value={option.value}>{option.title ?? _.startCase(option.value)}</Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        </>
    )
}

const Viewer = ({value, field}) => {
    if (!value || value.length === 0) return <em>No options selected</em>;
    return value.map((v) => {
        const option = _.find(field.options, ['value', v]);
        return tt(option);
    }).join(', ');
}

export default {Editor, Viewer, defaultValue: [], stringify: _.identity}
