import React from 'react'
import {BackTop, Form, Layout, PageHeader, Spin} from 'antd';
import _ from 'lodash';
import SiteMenu from "./SiteMenu";
import logo from '../logo.png';
import { GlobalSearch } from './elements/GlobalSearch';
import {Link} from 'react-router-dom';
import LocaleSelector from "./elements/LocaleSelector"
const {Header, Content, Sider} = Layout;

export const LayoutContext = React.createContext({collapsed: false, setCollapsed: null});

export default class extends React.Component {
    static contextType = LayoutContext;
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        let {children, backgroundColor = 'white', loading, ...props} = this.props;
        const {collapsed, setCollapsed} = this.context;

        props.style = props.style ?? {};
        props.style.backgroundColor = backgroundColor;

        // shorthand [title, link] for breadcrumbs and false for no breadcrumb
        if (props.breadcrumb === false) props.breadcrumb = {};
        props.breadcrumb = props.breadcrumb ?? [];
        if (_.isArray(props.breadcrumb)) {
            props.breadcrumb = {routes: props.breadcrumb.map(([breadcrumbName, path]) => ({breadcrumbName, path}))};
            props.breadcrumb.routes.push({breadcrumbName: props.title});
        }

        // navigate using history links, not hash links
        // see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        props.breadcrumb.itemRender = (route, params, routes) => {
            const i = routes.indexOf(route);
            const last = i === routes.length - 1;
            return (last || !route.path) ? (
                <span>{route.breadcrumbName}</span>
            ) : (
                <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }

        if (this.state.hasError) {
            props.title = 'Error!';
            children = <p>Something went wrong...</p>;
        } else if (loading) {
            props.breadcrumb.routes = [{breadcrumbName: 'Loading...'}];
            props.title = 'Loading...';
            children = (
                <div style={{padding: '25vh', textAlign: "center", verticalAlign: 'middle'}}>
                    <Spin size={'large'}/>
                </div>
            );
        }

        return (
            <Layout style={{minHeight: '100vh'}}>
                <Header className="site-layout-background" style={{
                    padding: 0,
                    borderBottom: '#ccc 1px solid',
                    position: 'fixed',
                    width: '100%',
                    zIndex: 999,
                }}>
                    <div style={{paddingRight: "30px", paddingBottom: "20px"}}>
                        <Link to='/app/dashboard'>
                            <img className="logo" src={logo} alt={'Red Canari'}/>
                        </Link>
                        {/* TODO: fix extremely janky css */}
                        <Form layout="inline" style={{float: 'right', marginTop: '15px'}} component="div">
                            <Form.Item><GlobalSearch /></Form.Item>
                            <Form.Item><LocaleSelector /></Form.Item>
                        </Form>
                    </div>
                </Header>

                <Layout className="site-layout" style={{marginTop: 64}}>
                    <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} breakpoint="lg" theme={'light'}
                           style={{
                               overflow: 'auto',
                               height: '100vh',
                               position: 'fixed',
                               left: 0,
                           }}>
                        <SiteMenu/>
                    </Sider>
                    <Content style={{marginLeft: collapsed ? 80 : 200}}>
                        <PageHeader {...props} />
                        <div style={{margin: 24}}>
                            {children}
                            <BackTop/>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}