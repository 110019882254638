import * as React from 'react';
import RichText from "../components/fields/RichText";
import {Checkbox, FormInstance, Input, InputNumber, Select, Tag} from "antd";
import * as _ from "lodash";
import HashTab from "../components/elements/HashTab";
import CVSSRating from "../components/fields/CVSSRating";
import ImageUpload from "../components/fields/ImageUpload";
import TableTab from "../components/tabs/TableTab";
import AutoSuggest from "../components/elements/AutoSuggest";
import Autofill from "../components/fields/Autofill";
import SimpleTab from "../components/tabs/SimpleTab";
import CheckboxGroup from "../components/fields/CheckboxGroup";
import CollectionTab from "../components/tabs/CollectionTab";
import SingletonTab from "../components/tabs/SingletonTab";
import {tt} from "./schemaUtils";
import ListUpload from "../components/fields/ListUpload";
import SimpleText from "../components/fields/SimpleText";
import ProtocolPort from "../components/fields/ProtocolPort";
import {SchemaContextProvider} from "../contexts/schema";
import DateRange from "../components/fields/DateRange";
import Relation from "../components/fields/Relation";
import Signature from "../components/fields/Signature";

export type FieldEditor<ValueType, Props> = React.ComponentType<{
    field: FieldSchema<ValueType, Props>,
    template?: boolean,
    form: FormInstance,
    value: ValueType,
    onChange: (...props: any) => void,
    [other: string]: any,
}>;

export type FieldViewer<ValueType, Props> = React.ComponentType<{
    field: FieldSchema<ValueType, Props>,
    value: ValueType,
    compact?: boolean
}>;

export interface FieldType<ValueType = any, Props = any> {
    Editor: FieldEditor<ValueType, Props>;
    Viewer: FieldViewer<ValueType, Props>;
    defaultValue: ValueType;
    stringify?: (value: ValueType, field: FieldSchema<ValueType, Props>) => Promise<any>;
    afterGet?: (doc: DBObject, field: FieldSchema<ValueType, Props>) => any;
    beforePut?: (doc: DBObject, field: FieldSchema<ValueType, Props>) => any;
    beforeDelete?: (doc: DBObject, field: FieldSchema<ValueType, Props>) => any;
    attachments?: {
        getBlob: (item: ValueType) => Promise<Blob[]>;
        defaultFileName: string;
    }
}

interface EditorTabProps {
    // Whether to render an extra card component around the UI
    card: boolean;
    // Form object. Use form.getFieldsValue([prop]) to read value[prop]
    form: FormInstance;
}

interface ViewerTabProps {
    // Whether to render an extra card component around the UI
    card: boolean;
    // The parent object to render, *not* the current tab object
    // e.g. { _id: 'abc', link$tab: 'def' } and not { _id: 'def', ... }
    value: DBObject;
}

interface TabType {
    Editor: React.ComponentType<EditorTabProps>;
    Viewer: React.ComponentType<ViewerTabProps>;
}

export const ProjectSchemasContext = React.createContext<Record<string, any>>({});
export const useProjectSchemas = () => React.useContext(ProjectSchemasContext);
export const useProjectSchema = (id: string) => {
    const schemas = useProjectSchemas();
    return schemas[id] ?? null;
}

const FieldTypes: Record<string, FieldType> = {
    text: SimpleText,
    textarea: {Editor: ({field, ...props}) => <Input.TextArea {...field} {...props} />, Viewer: ({value}) => <>{value}</>, defaultValue: ''},
    email: {Editor: ({form,...props}) => <Input {...props} type="email" />, Viewer: ({value}) => <a href={'mailto:' + value}>{value}</a>, defaultValue: ''},
    url: {Editor: ({form, ...props}) => <Input {...props} />, Viewer: ({value}) => <a href={value}>{value}</a>, defaultValue: ''},
    number: {Editor: ({form, ...props}) => <InputNumber {...props} />, Viewer: ({value}) => <>{value}</>, defaultValue: 0, stringify: (value) => value},
    checkbox: {
        Editor: ({form, onChange, value, ...props}) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} {...props} />,
        Viewer: ({value}) => <Checkbox checked={value} onChange={_.noop} />,
        defaultValue: false,
        stringify: (value) => value
    },
    select: {
        Editor: ({field, ...props}) => (
            <Select {...props}>
                {field.options.map((option) => (
                    <Select.Option value={option.value} key={option.value}>{option.title ?? _.startCase(option.value)}</Select.Option>
                ))}
            </Select>
        ),
        Viewer: ({field, value, compact}) => {
            const option = _.find(field.options, ['value', value ?? field.default]);
            if (!option) return <em>None selected</em>;
            const title = (compact && option.shortTitle) ? option.shortTitle : option.title;
            const text = title ?? _.startCase(option.value);
            if (option.color) return <Tag color={option.color}>{text}</Tag>;
            return text;
        },
        defaultValue: '',
        stringify: async (value, field) => {
            const option = _.find(field.options, ['value', value]);
            return tt(option);
        }
    } as FieldType<string, {options: {title?: string, shortTitle?: string, value: string, color?: string}[]}>,
    tags: {
        Editor: ({field, ...props}) => (
            <Select mode={field.mode ?? "tags"} {...props}>
                {field.options.map((option) => (
                    <Select.Option value={option.value} key={option.value}>{option.title ?? _.startCase(option.value)}</Select.Option>
                ))}
            </Select>
        ),
        Viewer: ({field, value, compact}) => {
            if (!value || value.length === 0) return <em>None</em>;
            return <>{value.map((tag, i) => {
                const option = _.find(field.options, ['value', tag]);
                if (!option) return <Tag key={i}>{tag}</Tag>;
                const title = (compact && option.shortTitle) ? option.shortTitle : option.title;
                const text = title ?? _.startCase(option.value);
                return <Tag key={i} color={option.color}>{text}</Tag>;
            })}</>;
        },
        defaultValue: [],
        stringify: async (value) => value,
    } as FieldType<string[], {mode?: "tags" | "multiple", options: {title?: string, shortTitle?: string, value: string, color?: string}[]}>,
    suggest: {
        Editor: ({field, ...props}) => <AutoSuggest {...props} options={field.options.map((s) => ({label: s, value: s}))} />,
        Viewer: ({value}) => <>{value}</>,
        defaultValue: ''
    } as FieldType<string, {options: string[]}>,
    DateRange, RichText, CVSSRating, ListUpload, ImageUpload, Autofill, CheckboxGroup, ProtocolPort, Relation, Signature,
}

/**
 * Look up a field's data from its type
 */
export const getFieldType = (type: string): FieldType => {
    // since table section acts as a field (it exists as a property on a database object)
    if (type === 'table') return {Editor: () => <em>?</em>, Viewer: () => <em>?</em>, defaultValue: []};
    return FieldTypes[type] ?? {Editor: Input, Viewer: ({value}) => <>{value}</>, defaultValue: ''};
}

/**
 * Render a form to create/edit a given section of a project schema.
 * Pass template=true to skip rendering hideInTemplate fields.
 */
export const SectionForm = (props: {schema: ProjectSchema, section: SectionSchema, template: boolean, form: FormInstance, card: boolean}) => {
    const {schema, section, template, form} = props;
    let children: React.ReactNode = <SimpleTab.Editor {...props} />;
    if (section.sections) {
        const TabTypes: Record<SectionType, TabType> = {simple: SimpleTab, table: TableTab, singleton: SingletonTab, collection: CollectionTab, mock: SimpleTab};
        const sections = template ? _.reject(section.sections, 'hideInTemplate') : section.sections;
        children = (
            <HashTab
                // @ts-ignore
                type="card"
                forceRender={true}
                panes={sections.map((tab) => ({
                    title: tt(tab),
                    hash: `#${tab.id}`,
                    render: () => {
                        const Tab = TabTypes[tab.type]?.Editor ?? (() => <em>Tab type not supported</em>);
                        return (
                            <SchemaContextProvider value={{schema, section: tab, template}}>
                                <Tab form={form} card={false} />
                            </SchemaContextProvider>
                        );
                    }
                }))}
            />
        );
    }

    return <SchemaContextProvider value={{schema, section, template}}>{children}</SchemaContextProvider>;
}
