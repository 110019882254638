import {AutoComplete, Input} from "antd";
import React, {useEffect, useState} from "react";

const protocols = [
    {protocol: 'FTP', number: 21},
    {protocol: 'SSH', number: 22},
    {protocol: 'HTTP', number: 80},
    {protocol: 'HTTPS', number: 443},
];
const defaultValue = {protocol: '', port: 0};

const ProtocolPort = ({field, value: initialValue, onChange, ...props}) => {
    const [protocol, setProtocol] = useState(defaultValue.protocol);
    const [port, setPort] = useState(defaultValue.number);
    useEffect(() => {
        setProtocol(initialValue.protocol);
        setPort(initialValue.number);
    }, [initialValue]);

    const handleProtocolChange = (next) => {
        next = next ?? '';
        setProtocol(next);
        onChange({protocol: next, number: port});
    }

    const handlePortChange = (e) => {
        setPort(e.target.value);
        onChange({protocol, number: e.target.value});
    }

    const handleSelect = (next) => {
        const option = JSON.parse(next);
        setProtocol(option.protocol);
        setPort(option.number);
        onChange(option);
    }

    const options = protocols.filter((p) => p.protocol.toLowerCase().includes(protocol.toLowerCase()))
        .map((p) => ({label: p.protocol, value: JSON.stringify(p)}))

    return (
        <Input.Group compact>
            <AutoComplete
                style={{width: '70%'}}
                options={options}
                onSelect={handleSelect}
                value={protocol}
                onChange={handleProtocolChange}
                allowClear
                {...props}
            />
            <Input
                style={{width: '30%'}}
                type="number"
                min={0}
                onChange={handlePortChange}
                value={port}
            />
        </Input.Group>
    )
}

export default {
    Editor: ProtocolPort,
    Viewer: ({value}) => <>{value.protocol} ({value.number})</>,
    stringify: (value) => value,
    defaultValue: {protocol: 'HTTP', port: 80}
}
