import {Checkbox, CheckboxProps, Typography} from "antd";
import * as React from "react";
import {FieldEditor, FieldType, FieldViewer} from "../../common/schema";
import moment from "moment";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {CheckboxChangeEvent} from "antd/es/checkbox";
const {Link} = Typography;

type VT = {signed: false, signer: null, ts: null} | {
    signed: true;
    signer: string;
    ts: moment.Moment;
};

type FP = Omit<CheckboxProps, 'checked'>;

const SignatureEditor: FieldEditor<VT, FP> = ({form, onChange, value, ...props}) => {
    const {i18n} = useTranslation();
    const {accounts} = useMsal();
    const name = accounts[0].name;

    const handleSign = (e: CheckboxChangeEvent) => {
        if (e.target.checked) onChange({signed: true, signer: name, ts: moment()});
    };

    const handleUnsign = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onChange({signed: false, signer: null, ts: null});
    }

    return (
        <Checkbox checked={value.signed} onChange={handleSign} {...props} disabled={value.signed}>
            {value.signed
                ? <><em>{value.signer}</em> at {value.ts.locale(i18n.language).format('lll')} (<Link onClick={handleUnsign}>clear</Link>)</>
                : 'Click to sign'}
        </Checkbox>
    )
}

const SignatureViewer: FieldViewer<VT, FP> = ({value}) => {
    const {i18n} = useTranslation();
    if (value.signed) return <>{value.signer} ({value.ts.locale(i18n.language).format('lll')})</>;
    return <em>No signature</em>;
}

export default {
    Editor: SignatureEditor,
    Viewer: SignatureViewer,
    defaultValue: {signed: false, signer: null, ts: null},
    beforePut: (doc, field) => {
        if (doc[field.id].ts) doc[field.id].ts = doc[field.id].ts.toDate();
    },
    afterGet: (doc, field) => {
        if (doc[field.id].ts) doc[field.id].ts = moment(doc[field.id].ts);
    },
    stringify: async ({signed, signer, ts}) => ({signed, signer, ts: ts?.toDate()})
} as FieldType<VT, FP>;
