import React, {useState, Suspense} from 'react';
import {Redirect, Route} from "react-router-dom";
import {Switch} from './routing';
import {LayoutContext} from "./components/Layout";

const Dashboard = React.lazy(() => import('./components/views/dashboard'));
const Project = React.lazy(() => import('./components/views/project'));
const ListClients = React.lazy(() => import('./components/views/clients'));
const Client = React.lazy(() => import('./components/views/client'));
const Templates = React.lazy(() => import('./components/views/templates'));
const Documents = React.lazy(() => import('./components/views/documents'));
const GenericType = React.lazy(() => import('./components/views/type'));
const Resolve = React.lazy(() => import("./components/views/resolve"));
const Changelog = React.lazy(() => import("./components/views/changelog"));

const App = () => {
    // side menu drawer
    const [collapsed, setCollapsed] = useState(false);
    const handleCollapsed = (next, type) => {
        if (type === 'clickTrigger') setCollapsed(next);
    }
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <LayoutContext.Provider value={{collapsed, setCollapsed: handleCollapsed}}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app/dashboard" />
                    </Route>
                    <Route path="/app/dashboard" component={Dashboard} />

                    <Route path='/app/templates' component={Templates} />

                    <Route exact path='/app/projects'>
                        <Redirect to='/app/project/list' />
                    </Route>
                    <Route path='/app/project' component={Project} />

                    {/*Clients View*/}
                    <Route path='/app/clients' component={ListClients} />
                    <Route path='/app/client' component={Client} />

                    {/* Documents Views */}
                    <Route path='/app/documents' component={Documents} />

                    {/*Changelog View*/}
                    <Route path='/app/changelog' component={Changelog} />

                    {/* Conflict Resolution View */}
                    <Route path='/app/resolve/:itemId' component={Resolve} />

                    {/* Section/Form Views */}
                    <Route path='/app/:schemaId/:type' component={GenericType} />
                </Switch>
            </LayoutContext.Provider>
        </Suspense>
    );
}


export default App;


