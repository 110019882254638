import _ from "lodash";
import {Card, Col, Form, Row} from "antd";
import React from "react";
import {getFieldType} from "../../common/schema";
import {tt} from "../../common/schemaUtils";
import {useSchema} from "../../contexts/schema";

const Editor = ({card, form}) => {
    const {section, template} = useSchema();
    const fields = template ? _.reject(section.fields, 'hideInTemplate') : section.fields;
    let child = (
        <Row gutter={16}>
            {fields.map((field) => {
                const FieldType = getFieldType(field.type);
                if (field.omitFormItem) {
                    return (
                        <Col span={field.width || 12} key={field.id}>
                            <FieldType.Editor field={field} form={form} />
                        </Col>
                    )
                } else {
                    if (field.title === false) {
                        field.fieldProps = field.fieldProps ?? {};
                        field.fieldProps.noStyle = true;
                        field.fieldProps.label = undefined;
                    }
                    return (
                        <Col span={field.width || 12} key={field.id}>
                            <Form.Item name={field.id} label={tt(field)} {...field.fieldProps}>
                                <FieldType.Editor field={field} form={form} />
                            </Form.Item>
                        </Col>
                    )
                }
            })}
        </Row>
    );
    if (card) child = <Card>{child}</Card>;
    return child;
}

const Viewer = ({value}) => {
    const {section} = useSchema();
    return (
        <Row gutter={16}>
            {section.fields.map((field) => {
                const FieldType = getFieldType(field.type);
                const fieldValue = value[field.id] ?? FieldType.defaultValue;
                return (
                    <Col span={field.width || 12} key={field.id}>
                        {field.title !== false && <strong>{tt(field)}<br /></strong>}
                        <FieldType.Viewer field={field} value={fieldValue} />
                    </Col>
                )
            })}
        </Row>
    );
}

export default {Editor, Viewer}
