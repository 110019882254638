import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import TableTab from "./TableTab";
import SimpleTab from "./SimpleTab";
import {tt} from "../../common/schemaUtils";
import {SchemaContextProvider, useSchema} from "../../contexts/schema";
import {useDB} from "../../contexts/database";

const {Title} = Typography;

const EditButton = ({parent, value, hash}) => {
    const history = useHistory();
    const {schema, section} = useSchema();

    const handleClick = () => {
        let id = value?._id ? `${value._id}/edit` : `new/${parent._id}`;
        history.push({pathname: `/app/${schema.id}/${section.id}/${id}`, hash});
    }

    return <Button icon={<EditOutlined/>} type="link" onClick={handleClick} />;
}

const SingletonTab = ({value}) => {
    const [data, setData] = useState({});
    const db = useDB();
    const {schema, section, template} = useSchema();

    const dataId = value ? value['link$' + section.id] : null;
    useEffect(() => {
        if (dataId) db.smartGet(dataId, {schema: section}).then((doc) => setData(doc));
    }, [db, section.id, dataId])

    if (section.sections) return (
        <>
            {section.sections.map((tab) => {
                let children = 'Not implemented yet';
                if (tab.type === 'table') children = <TableTab.Viewer value={data[tab.id]}/>
                if (tab.type === 'simple') children = <SimpleTab.Viewer value={data}/>
                return (
                    <React.Fragment key={tab.id}>
                        <Title level={5}>
                            {tt(tab)}
                            {(tab.type === 'simple' || tab.type === 'table') &&
                                <EditButton parent={value} value={data} hash={tab.id}/>}
                        </Title>
                        <SchemaContextProvider key={tab.id} value={{schema, section: tab, template}}>
                            {children}
                        </SchemaContextProvider>
                    </React.Fragment>
                )
            })}
        </>
    )

    return (
        <>
            <Title level={5}>
                {tt(section)}
                <EditButton parent={value} value={data} />
            </Title>
            <SimpleTab.Viewer value={data} />
        </>
    )
};

export default {
    Viewer: SingletonTab,
    Editor: ({form}) => <SingletonTab value={form.getFieldsValue(true)} />,
};
