import PouchDB from "pouchdb";
import PouchDBFind from 'pouchdb-find';
import PouchDBDebug from 'pouchdb-debug';
import {useContext, useEffect, useState} from "react";
import * as React from 'react';
import {config} from "../config";
import {useMsal} from "@azure/msal-react";
import {ProjectSchemasContext} from "../common/schema";
import {Modal, Button} from "antd";
import _ from "lodash";
import DBInterface from "../common/dbInterface";

PouchDB.plugin(PouchDBDebug);
PouchDB.plugin(PouchDBFind);
if (process.env.NODE_ENV !== "production") {
    PouchDB.debug.enable('pouchdb:*');
}

// @ts-ignore
const DatabaseContext = React.createContext<DBInterface>(null);
export const useDB = (): DBInterface => useContext(DatabaseContext);

const DatabaseProvider: React.ComponentType = ({children}) => {
    const {instance, accounts, inProgress} = useMsal();
    const authOptions = {
        account: accounts[0],
        scopes: config.scopes
    };

    const [db, setDB] = useState<DBInterface | null>(null);
    const [schemas, setSchemas] = useState<Record<string, ProjectSchema> | null>(null);
    const [sessionModalVisible, setSessionModalVisible] = useState(false);

    useEffect(() => {
        const remote = new DBInterface(config.couchDBEndpoint, {
            currentUser: accounts[0]?.name as string,
            fetch: async (url, opts) => {
                let accessToken;
                try {
                    accessToken = (await instance.acquireTokenSilent(authOptions)).accessToken;
                } catch {
                    await instance.acquireTokenRedirect(authOptions);
                }
                (opts?.headers as Headers).set('Authorization', `Bearer ${accessToken}`);
                const res = await fetch(url, opts);
                if (res.status === 401) {
                    setSessionModalVisible(true);
                }
                return res;
            }
        });
        window.db = remote;
        setDB(remote);

        // keep schema context updated
        remote.find({selector: {'\\$type': '$schema'}})
            .then(({docs}) => setSchemas(_.keyBy(docs.map(doc => doc.schema), 'id')))
            .catch(console.error);

        const changes = remote.changes<DBObject>({
            since: 'now',
            live: true,
            include_docs: true
        }).on('change', (change) => {
            if (change.doc?.$type === '$schema') {
                const {schema} = change.doc;
                if (change.deleted) {
                    setSchemas((schemas) => _.omit(schemas, schema.id));
                } else {
                    setSchemas((schemas) => {
                        if (schemas) schemas[schema.id] = schema;
                        return schemas;
                    });
                }
            }
        });

        return function cleanup() {
            if (changes) changes.cancel();
        }
    }, [accounts, inProgress, instance]);
    if (!db || !schemas) return <>Loading...</>;

    return (
        <DatabaseContext.Provider value={db}>
            <ProjectSchemasContext.Provider value={schemas}>
                {children}
            </ProjectSchemasContext.Provider>
            <Modal
                visible={sessionModalVisible}
                title="Session Expired"
                onCancel={() => setSessionModalVisible(false)}
                footer={
                    <Button danger onClick={() => instance.acquireTokenRedirect(authOptions)}>Refresh</Button>
                }
            >
                <p>Please refresh the page to reauthenticate.</p>
            </Modal>
        </DatabaseContext.Provider>
    );
};

export default DatabaseProvider;
