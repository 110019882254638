import React from 'react';
import {useHistory} from "react-router-dom";
import _ from "lodash";
import {findInSchema, isInternal, tt} from "../../common/schemaUtils";
import {getFieldType} from "../../common/schema";
import DBTable from "../elements/DBTable";
import {useSchema} from "../../contexts/schema";
import {useDB} from "../../contexts/database";

const CollectionTab = ({value}) => {
    const db = useDB();
    const history = useHistory();
    const {schema, section, template} = useSchema();
    const tableProps = {};
    let columns = section.tableColumns
        ? section.tableColumns.map((id) => findInSchema(section, id) ?? {id})
        : section.fields;
    if (template) columns = _.reject(columns, 'hideInTemplate');
    tableProps.columns = columns.map((field) => ({
        key: field.id,
        dataIndex: field.id,
        title: field.shortTitle ?? tt(field),
        sorter: field.sortable,
        render: (val) => {
            const FieldType = getFieldType(field.type);
            const fieldValue = val ?? FieldType.defaultValue;
            return <FieldType.Viewer field={field} value={fieldValue} compact />
        }
    }));

    if (section.autofill) {
        tableProps.onQuickAdd = async (template) => {
            const raw = _.omitBy(template, (val, key) => isInternal(key));
            raw.link$parent = value._id;
            await db.smartPut(raw, section.id, section);
            // do not manually add a row to the end of the table (will instead force a full refresh)
            // it is not guaranteed the new row will be last in the current sorting state of the table
            return null;
        }
    }

    if (value) {
        tableProps.queryIndex = 'by_parent_and_type';
        tableProps.queryParam = value._id;
    }

    return (
        <DBTable
            type={section.id}
            searchFields={section.searchFields}
            onAdd={() => history.push(value ? `/app/${schema.id}/${section.id}/new/${value._id}` : `/app/${schema.id}/${section.id}/new`)}
            onRowClick={(row) => history.push(`/app/${schema.id}/${section.id}/${row._id}/edit`)}
            size="small"
            {...tableProps}
        />
    )
}

export default {
    Editor: ({form}) => <CollectionTab value={form.getFieldsValue(true)} />,
    Viewer: CollectionTab
};
