import React, {useContext} from 'react'
import {useRouteMatch, useHistory} from "react-router-dom";
import {
    BankTwoTone, CopyTwoTone, FolderOpenTwoTone, DiffTwoTone, HomeTwoTone, LogoutOutlined
} from "@ant-design/icons";
import {Menu} from "antd";
import {LayoutContext} from "./Layout";
import {useTranslation} from "react-i18next";
import metadata from "../generatedMetadata.json";

const {SubMenu} = Menu;

export default ({mode = 'inline', theme = 'light'}) => {
    const {path} = useRouteMatch();
    const history = useHistory();
    const {collapsed} = useContext(LayoutContext);
    const {t} = useTranslation('navigation');

    const handleClick = ({key}) => history.push(key);

    return (
        <Menu defaultSelectedKeys={[path]} mode={mode} theme={theme} onClick={handleClick}>
            <Menu.Item key="/app/dashboard" icon={<HomeTwoTone/>}>
                {t('dashboard')}
            </Menu.Item>
            <SubMenu key="/app/clients" icon={<BankTwoTone twoToneColor="#52c41a"/>} title={t('clients.title')}>
                <Menu.Item key="/app/client/new">{t('clients.add')}</Menu.Item>
                <Menu.Item key="/app/clients">{t('clients.browse')}</Menu.Item>
            </SubMenu>
            <SubMenu key="/app/projects" icon={<FolderOpenTwoTone twoToneColor="#eb2f96"/>} title={t('projects.title')}>
                <Menu.Item key="/app/project/new">{t('projects.add')}</Menu.Item>
                <Menu.Item key="/app/projects">{t('projects.browse')}</Menu.Item>
            </SubMenu>
            <Menu.Item key="/app/templates/dashboard" icon={<CopyTwoTone twoToneColor='#ebb434'/>}>
                {t('templates.title')}
            </Menu.Item>
            {process.env.NODE_ENV !== 'production' ? (
                <>
                    <SubMenu key="/app/documents" icon={<DiffTwoTone twoToneColor='#0000FF'/>} title={t('documents.title')}>
                        <Menu.Item key="/app/documents/schemas">{t('documents.schemas')}</Menu.Item>
                        <Menu.Item key="/app/documents/files">{t('documents.files')}</Menu.Item>
                    </SubMenu>
                </>
            ) : (
                <Menu.Item key="/app/documents/files" icon={<DiffTwoTone twoToneColor='#0000FF'/>}>{t('documents.title')}</Menu.Item>
            )}
            <Menu.Item key="9" icon={<LogoutOutlined/>}>{t('logout')}</Menu.Item>
            {!collapsed && (
                <Menu.Item key="/app/changelog">
                    v{metadata.packageVersion} <small>({metadata.gitBranch}/{metadata.gitCommitHash})</small>
                </Menu.Item>
            )}
        </Menu>
    );
};
