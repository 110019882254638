import _ from "lodash";

// https://www.first.org/cvss/specification-document#7-4-Metric-Values
const METRIC_VALUES = {
    AV: {
        N: 0.85,
        A: 0.62,
        L: 0.55,
        P: 0.2
    },
    MAV: {
        X: undefined,
        N: 0.85,
        A: 0.62,
        L: 0.55,
        P: 0.2
    },
    AC: {
        L: 0.77,
        H: 0.44
    },
    MAC: {
        X: undefined,
        L: 0.77,
        H: 0.44
    },
    PR: {
        N: 0.85,
        L: (vector) => vector.S === 'U' ? 0.62 : 0.68,
        H: (vector) => vector.S === 'U' ? 0.27 : 0.5
    },
    MPR: {
        X: undefined,
        N: 0.85,
        L: (vector) => vector.MS === 'U' ? 0.62 : 0.68,
        H: (vector) => vector.MS === 'U' ? 0.27 : 0.5
    },
    UI: {
        N: 0.85,
        R: 0.62
    },
    MUI: {
        X: undefined,
        N: 0.85,
        R: 0.62
    },
    C: {
        N: 0,
        L: 0.22,
        H: 0.56
    },
    MC: {
        X: undefined,
        N: 0,
        L: 0.22,
        H: 0.56
    },
    I: {
        N: 0,
        L: 0.22,
        H: 0.56
    },
    MI: {
        X: undefined,
        N: 0,
        L: 0.22,
        H: 0.56
    },
    A: {
        N: 0,
        L: 0.22,
        H: 0.56
    },
    MA: {
        X: undefined,
        N: 0,
        L: 0.22,
        H: 0.56
    },
    E: {
        X: 1,
        H: 1,
        F: 0.97,
        P: 0.94,
        U: 0.91
    },
    RL: {
        X: 1,
        U: 1,
        W: 0.97,
        T: 0.96,
        O: 0.95
    },
    RC: {
        X: 1,
        C: 1,
        R: 0.96,
        U: 0.92
    },
    CR: {
        X: 1,
        H: 1.5,
        M: 1,
        L: 0.5
    },
    IR: {
        X: 1,
        H: 1.5,
        M: 1,
        L: 0.5
    },
    AR: {
        X: 1,
        H: 1.5,
        M: 1,
        L: 0.5
    },
    S: {
        U: 'U',
        C: 'S'
    },
    MS: {
        U: 'U',
        C: 'S'
    }
}

export const METRICS = ['AV', 'AC', 'PR', 'UI', 'S', 'C', 'I', 'A', 'E', 'RL', 'RC', 'MAV', 'MAC', 'MPR', 'MUI', 'MS', 'MC', 'MI', 'MA', 'CR', 'IR', 'AR'];
export const CONTEXTLESS_METRICS = ['AV', 'AC', 'PR', 'UI', 'S', 'C', 'I', 'A', 'E', 'RL', 'RC'];

/**
 * Reference: https://www.first.org/cvss/specification-document#Appendix-A---Floating-Point-Rounding
 * @param input floating point number
 * @returns {number} number rounded up to 1 decimal
 */
const roundUp = (input) => {
    const int_input = Math.round(input * 100000);
    if ((int_input % 10000) === 0) {
        return int_input / 100000.0;
    }
    return (Math.floor(int_input / 10000) + 1) / 10.0;
}

const getMetricValue = (vector, metric) => {
    const value = vector[metric];
    const v = METRIC_VALUES[metric][value];
    if (typeof v === 'function') {
        return v(vector);
    }
    return v;
}

const vectorToNumericalVector = (vector) => {
    vector = _.pickBy(vector, _.identity);
    for (let m in vector) {
        if (METRICS.indexOf(m) === -1)
            continue;
        if (vector.hasOwnProperty(m))
            vector[m] = getMetricValue(vector, m);
    }
    return vector;
}

const calculateImpactSubScore = (nv) => {
    return 1 - ((1 - nv.C) * (1 - nv.I) * (1 - nv.A));
}

const calculateImpact = (nv) => {
    const iss = calculateImpactSubScore(nv);
    if (nv.S === 'U')
        return 6.42 * iss;
    return 7.52 * (iss - 0.029) - 3.25 * (iss - 0.02) ** 15;
}

const calculateExploitability = (nv) => {
    return 8.22 * nv.AV * nv.AC * nv.PR * nv.UI;
}

const calculateBaseScore = (nv) => {
    const impact = calculateImpact(nv);
    if (impact <= 0) {
        return {
            impact: 0,
            exploitability: 0,
            base: 0
        }
    } else {
        const exploitability = calculateExploitability(nv);
        if (nv.S === 'U')
            return {
                impact: impact.toFixed(1) * 1.0,
                exploitability: exploitability.toFixed(1) * 1.0,
                base: roundUp(Math.min(impact + exploitability, 10))
            };
        return {
            impact: impact.toFixed(1) * 1.0,
            exploitability: exploitability.toFixed(1) * 1.0,
            base: roundUp(Math.min(1.08 * (impact + exploitability), 10))
        };
    }
}

export const parseVector = (vectorString, ignoreEnvironmental = false) => {
    const vectorMap = {}
    const metrics = ignoreEnvironmental ? CONTEXTLESS_METRICS : METRICS;

    _.split(vectorString, '/').forEach((element, i) => {
        const [metric, value] = _.split(element, ':');
        if (metric.toUpperCase() === 'CVSS') {
            vectorMap.version = value;
        } else if (metrics.indexOf(metric) !== -1) {
            vectorMap[metric.toUpperCase()] = value;
        } else {
            console.log(`Invalid metric detected: ${metric}`);
        }
    });

    return vectorMap;
}

const calculateTemporalScore = (baseScore, nv) => {
    return roundUp(baseScore.base * nv.E * nv.RL * nv.RC);
}

const calculateModifiedImpactSubscore = (nv) => {
    return Math.min(1 - ((1 - nv.CR * (nv.MC || nv.C)) * (1 - nv.IR * (nv.MI || nv.I)) * (1 - nv.AR * (nv.MA || nv.A))), 0.915);
}

const calculateModifiedImpact = (nv) => {
    const miss = calculateModifiedImpactSubscore(nv);
    if (nv.MS === 'U')
        return 6.42 * miss;
    return 7.52 * (miss - 0.029) - 3.25 * (miss * 0.9731 - 0.02) ** 13
}

const calculateModifiedExploitability = (nv) => {
    return 8.22 * (nv.MAV || nv.AV) * (nv.MAC || nv.AC) * (nv.MPR || nv.PR) * (nv.MUI || nv.UI);
}

const calculateEnvironmentalScore = (nv) => {
    const modifiedImpact = calculateModifiedImpact(nv);
    if (modifiedImpact <= 0) {
        return {
            environmental: 0,
            impact: 0
        }
    } else {
        const modifiedExploitability = calculateModifiedExploitability(nv);
        if (nv.MS === 'U') {
            return {
                environmental: roundUp(roundUp(Math.min((modifiedImpact + modifiedExploitability), 10)) * nv.E * nv.RL * nv.RC),
                impact: modifiedImpact.toFixed(1) * 1.0
            }
        }
        return {
            environmental: roundUp(roundUp(Math.min(1.08 * (modifiedImpact + modifiedExploitability), 10)) * nv.E * nv.RL * nv.RC),
            impact: modifiedImpact.toFixed(1) * 1.0
        }
    }
}

export const calculateCVSS31Scores = (vector, ignoreEnvironmental = false) => {

    if (typeof vector === 'string')
        vector = parseVector(vector);

    vector = vectorToNumericalVector(vector);

    const base = calculateBaseScore(vector);
    const temporal = calculateTemporalScore(base, vector);
    const environmental = ignoreEnvironmental ? {} : calculateEnvironmentalScore(vector);

    return {
        base,
        temporal,
        environmental,
        overall: environmental?.environmental || temporal || base.base
    };

}

export const cvssVectorMapToString = (vectorMap, ignoreEnvironmental = false) => {
    const vector = ['CVSS:3.1'];

    (ignoreEnvironmental ? CONTEXTLESS_METRICS : METRICS).forEach((m) => {
        if (vectorMap[m])
            vector.push(`${m}:${vectorMap[m]}`);
    });

    if (vector.length === 1)
        return '';

    return _.join(vector, '/');
}