import React, {useEffect} from "react";
import {Route, Switch as RouterSwitch, useHistory} from "react-router-dom";
import {message} from "antd";

const NotFound = () => {
    const history = useHistory();
    useEffect(() => {
        message.error('Page not found! Redirecting to dashboard');
        history.replace('/app/dashboard');
    })
    return null;
};

export const Switch = ({children}) => (
    <RouterSwitch>
        {children}
        <Route path="*" component={NotFound} />
    </RouterSwitch>
);
