import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Form } from "antd";
import _ from "lodash";
import {EditOutlined} from "@ant-design/icons";
import {isInternal} from "../../common/schemaUtils";


const EditableCell = ({location, onEdit = null, title, record, form, children, style, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const editable = !!onEdit;
    const formKey = editable? location.row + " " + location.column: "";
    if (editable){
        form.setFieldsValue({
            [formKey]: record[location.column],
        });
    }

    const inputRef = useRef(null);

    
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
      
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
    };
  
    const save = async () => {
      try {
        const fields = await form.validateFields();
        toggleEdit();
        onEdit(fields[formKey]);
      } catch (errInfo) {
        //console.log('Save failed:', errInfo);
      }
    };
    
  
    if (editable) {
        
        let childNode = children;
        childNode = editing ? (
            <Form.Item
              style={{
                margin: 0,
              }}
              name={formKey}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                              
            </Form.Item>
          ) : (
            <>
              {children}
              {editable && 
                    <Button size="small" onClick={ (e) => {toggleEdit()}} style={{position: "absolute", right: "16px", top: "16px"}}>
                        <EditOutlined />
                    </Button>}
            </>
          );
        
    
    
        return(
          
              <td {...restProps} style={{...style, paddingRight: "50px"}}>{childNode} </td>
          )
    
    } else {
        return <td {...restProps} style={style}>{children} </td>
    }
        
  };
  
/**
 * 
 * @param {left.obj, left.title} left left object to compare and the title of the table 
 * @param {right.obj, right.title} right
 */
const DiffViewer = ({ left, right, onChange}) => {
        
    const [form] = Form.useForm();
    const [selected, setSelected] = useState([]);
    const [cells, setCells] = useState([]);    
    const [totalSelected, setTotalSelected] = useState(0);
    const [revision, setRevision] = useState({});

    //check for color codes https://www.w3schools.com/colors/colors_mixer.asp?colorbottom=FFE4E1&colortop=FFFFFF
    const editableCellProps = (columnIndex) => {
        return {
            render: (text, record) => (
                <pre>
                    <code>
                        {text}
                    </code>
                </pre>
            ),
            onCell: (record, rowIndex) => {
                const style = {};
                if (!record.editable) style.backgroundColor = "#D3D3D3";
                if (record.selected === columnIndex) style.backgroundColor = "#FFADA3";
                return {
                    onClick: () => {
                        if (!record.editable || record.selected === columnIndex) return;
                        setSelected((selected) =>{
                            selected[cells[rowIndex]["selected"]]--; 
                            selected[columnIndex]++;
                            return selected;
                        })
                        setCells((cells) => {
                            cells[rowIndex] = {...cells[rowIndex], "selected": columnIndex};
                            return [...cells];
                        })
                        setRevision((revision) =>{
                            revision[record.key] = record[columnIndex];
                            onChange(revision);
                            return revision;
                        })
                    },
                    style: style,
                    location: {row: rowIndex, column: columnIndex},
                    onEdit: record.editable && columnIndex === 1? (newValue) => {
                        setCells((cells) => {
                            cells[rowIndex] = {...cells[rowIndex], [columnIndex]: newValue};
                            return [...cells];
                        })
                        setRevision((revision) =>{
                            revision[record.key] = newValue;
                            onChange(revision);
                            return revision;
                        })
                    }: null,
                    form,
                    record,
                    title: record.key
                }
            },
            onHeaderCell: () => {
                return {
                    onClick: () => {
                        if (selected[columnIndex] === totalSelected) return;

                        const _cells = [...cells]
                        const _revision = {...revision}
                        for (let i of _cells){
                            if (i["editable"]){
                                i["selected"] = columnIndex;
                                _revision[i.key] = i[columnIndex];
                            }
                        }
                        
                        setCells(_cells);                    
                        setRevision(_revision);
                        setSelected((selected) => {
                            for (let i in selected){
                                selected[i] = 0;
                            }
                            selected[columnIndex] = totalSelected;    
                            return selected;
                        });
                        onChange(_revision);    
                    },
                    style:  selected[columnIndex] === totalSelected? { backgroundColor: "#ff504f" } : {}, 
                }
            },
            dataIndex: columnIndex
        }
    }

    
    useEffect(() => {

        
        //const [form] = Form.useForm();
        //Get a list of keys
        const keySet = new Set();

        for (const i of Object.keys(left.obj)) {
            if (!isInternal(i)) {
                keySet.add(i);
            }
        }

        for (const i of Object.keys(right.obj)) {
            if (!isInternal(i)) {
                keySet.add(i);
            }
        }

        const keyList = Array.from(keySet).sort()

        //set up default tableValue
        const cells = [];
        const selected = [0, 0, 0];
        let totalSelected = 0;

        for (let i of keyList) {
            const leftValue = _.cloneDeep(left.obj[i]);
            const rightValue = _.cloneDeep(right.obj[i]);
            
            const rowValues = {"key": i, 1: leftValue, 2: rightValue};
            if (leftValue !== rightValue) {
                cells.push({"editable": true, "selected": 1, ...rowValues});
                selected[1]++;
                totalSelected++;
            } else {
                cells.push({"editable": false, ...rowValues});
                
            }
        }

        cells.sort((a, b) => { return b.editable - a.editable });

        setTotalSelected(totalSelected);
        setCells(cells);
        setSelected(selected);
        const revision = _.cloneDeep(left.obj)
        setRevision(revision);
        onChange(revision);
    }, [left.obj, right.obj]);
      
    const columns = [
        {
            title: '',
            dataIndex: 'key',
            key: 'type',
            width: "200px",
            render: (text) => (
                <pre>
                    <code>
                        <span className="hashkey">{_.startCase(text)}</span>
                    </code>
                </pre>
            ),
            onCell: ({editable}) => {
                return {
                    style: !editable? { backgroundColor: "#D3D3D3" }: {}
                }
            }
        },
        {
            title: left.title,
            key: 'newestVersion',
            ...editableCellProps(1)
        },
        {
            title: right.title,
            key: 'originalVersion',
            ...editableCellProps(2)
        }
    ];

    const components = {
        body: {
          cell: EditableCell,
        },
      };
      
    return (
        
        <Form form={form} component={false}>
            <Table columns={columns} dataSource={cells} scroll={{ y: 500 }} components={components} pagination={false} size="small" />
        </Form>
    )
}

export default DiffViewer;
