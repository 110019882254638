import _ from "lodash";
import {getFieldType} from "./schema";

/**
 * Find a section/field in schema given ID
 * @param {SchemaObject} schema Schema object to search through
 * @param {string} id ID to search for
 * @returns {null|*} Schema object or null if not exists
 */
export const findInSchema = (schema, id) => {
    if (schema.id === id) return schema;

    if (schema.fields) {
        for (const field of schema.fields) {
            if (field.id === id) return field;
        }
    }

    if (schema.sections) {
        for (const section of schema.sections) {
            const o = findInSchema(section, id);
            if (o) return o;
        }
    }

    return null;
}


export const filterInSchema = (schema, predicate, recursive = false) => {
    let matches = [];

    if (schema.fields) {
        for (const field of schema.fields) {
            if (predicate(field)) matches.push(field);
        }
    }


    if (schema.sections) {
        for (const section of schema.sections) {
            if (!recursive && (section.type === "singleton" || section.type === "collection")) continue;
            matches = matches.concat(filterInSchema(section, predicate, recursive));
        }
    }

    return matches;
}

/**
 * Create an array of all fields in a schema
 * @param {SchemaObject} schema Schema to consume
 * @param {boolean} isTemplate Whether to ignore non-templatable fields
 * @returns {FieldSchema[]}
 */
export const flattenSchema = (schema, isTemplate) => {
    let fields = schema.fields ?? [];
    if (schema.sections) {
        const sections = isTemplate ? _.reject(schema.sections, 'hideInTemplate') : schema.sections;
        for (const section of sections) {
            if (section.type === 'simple') {
                const nextFields = isTemplate ? _.reject(section.fields, 'hideInTemplate') : section.fields;
                fields = fields.concat(nextFields);
            } else if (section.type === 'table') {
                if (!isTemplate || !section.hideInTemplate) fields.push(section);
            }
        }
    }
    return fields;
}

/**
 * Checks if propertyName is an internal property
 * @param propertyName {string}
 * @returns {boolean}
 */
export const isInternal = (propertyName) => {
    return propertyName.startsWith("_") || propertyName.includes("$");
}

/**
 * Generate a default value for an object following schema
 * @param schema Schema (or section) to consume
 * @param isTemplate Whether to ignore non-templatable fields
 * @returns {*}
 */
export const generateDefaultValue = (schema, isTemplate) => {
    let obj = {};

    if (schema.fields) {
        const fields = isTemplate ? _.reject(schema.fields, 'hideInTemplate') : schema.fields;
        for (const field of fields) {
            const FieldType = getFieldType(field.type);
            obj[field.id] = field.default ?? FieldType.defaultValue;
        }
    }

    if (schema.sections) {
        const sections = isTemplate ? _.reject(schema.sections, 'hideInTemplate') : schema.sections;
        for (const section of sections) {
            if (section.type === 'simple') {
                Object.assign(obj, generateDefaultValue(section, isTemplate));
            } else if (section.type === 'table') {
                obj[section.id] = [];
            } else if (section.type === 'singleton') {
                obj[`link$${section.id}`] = null;
            } else if (section.type === 'collection') {
                obj[`link$${section.id}`] = [];
            }
        }
    }

    return obj;
}

export const SEEN = Symbol('object seen by stringifyBySchema');

/**
 * Stringify all values in object according to a given schema.
 *
 * @param {DBObject} obj Current object under consideration
 * @param {Map<string, DBObject[]>} context Index of all objects in the project
 * @param {SchemaObject} schema Current subset of the schema under consideration
 */
export const stringifyBySchema = async (obj, context, schema) => {
    if (schema.fields) {
        for (const field of schema.fields) {
            if (obj[field.id]) {
                // TODO: get rid of custom logic
                if (field.type === 'Relation') {
                    const seenIds = obj[field.id];
                    for (const id of seenIds) {
                        const doc = _.find(context[field.relationType], ['_id', id]);
                        doc[SEEN] = true;
                    }
                }

                const FieldType = getFieldType(field.type);
                obj[field.id] = FieldType.stringify ? await FieldType.stringify(obj[field.id], field) : obj[field.id].toString();
            }
        }
    }

    if (schema.sections) {
        for (const section of schema.sections) {
            if (section.type === 'simple') {
                // if simple tab, append to current object
                obj = await stringifyBySchema(obj, context, section);
            } else if (section.type === 'singleton') {
                // if singleton, write to obj.section property
                const id = obj[`link$${section.id}`];
                const child = _.find(context[section.id], ['_id', id]);
                if (child) {
                    obj[section.id] = await stringifyBySchema(child, context, section);
                }
            } else if (section.type === 'collection' || section.type === 'table') {
                // if array, recursively traverse
                const children = (section.type === 'collection')
                    ? _.filter(context[section.id], ['link$parent', obj._id]) // collection children stored in other docs
                    : obj[section.id]; // table children stored in this doc
                obj[section.id] = await Promise.all(children.map((child) => {
                    return stringifyBySchema(child, context, section);
                }));
            }
        }
    }

    // return _.omitBy(obj, (val, key) => isInternal(key));
    return obj;
}

export const treeShake = (tree, schema) => {
    if (schema.sections) {
        for (const section of schema.sections) {
            if (section.treeShake) {
                tree[section.id] = tree[section.id].filter((child) => child[SEEN]);
            }
        }
    }

    return tree;
}

/**
 * Get the human-readable title for a schema entry
 * @param obj Schema entry
 * @returns {string}
 */
export const tt = (obj) => {
    if (!obj) return '';
    if (obj.title) return obj.title;
    return _.startCase(obj.id);
}
