import React from 'react';
import {Tabs} from 'antd';
import {useHistory, useLocation} from "react-router-dom";

const {TabPane} = Tabs;

/**
 * Tab pane that saves state in history
 *
 * @param panes {{title: string, render: React.FunctionComponent, hash: string}[]} Panes
 * @param forceRender {boolean} Set to true to pre-render all panes
 * @param type {string} Basic style of tabs
 */
const HashTab = ({panes, forceRender = false, type = 'line'}) => {
    const location = useLocation();
    const history = useHistory();

    const handleTabChange = (hash) => {
        history.push({...location, hash});
    };

    return (
        <div className={type === 'card' ? 'card-container' : ''}>
            <Tabs
                onChange={handleTabChange}
                defaultActiveKey={panes[0].hash}
                activeKey={location.hash || undefined}
                type={type}
            >
                {panes.map(({title, render, hash}) => (
                    <TabPane tab={title} key={hash} forceRender={forceRender}>
                        {render()}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
}

export default HashTab;
