import React, {useEffect, useState} from 'react';
import {AutoComplete} from "antd";

/**
 * Extension of antd.AutoComplete that filters given options
 */
const AutoSuggest = ({onChange, value, options = [], ...props}) => {
    const [rawValue, setRawValue] = useState(value);
    const [activeOptions, setActiveOptions] = useState([]);
    useEffect(() => { setRawValue(value) }, [value]);
    useEffect(() => { setActiveOptions(options) }, [options]);

    const handleChange = (next) => {
        setRawValue(next);
        setActiveOptions(options.filter(x => x.value.toLowerCase().startsWith(next.toLowerCase())));
        onChange(next);
    };

    return <AutoComplete {...props} value={rawValue} onChange={handleChange} options={activeOptions} />
}

export default AutoSuggest;
