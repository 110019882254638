import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import { promptConflict } from "./antUtils";
import {findInSchema, flattenSchema, generateDefaultValue} from "./schemaUtils";
import {getFieldType} from "./schema";
import PouchDB from "pouchdb";

class ConstraintError extends Error {}

type Options = PouchDB.Core.Options & {schema?: SchemaObject};

interface PutResponse {
    ok: boolean | 'update';
    id: string;
    rev: string;
}

/**
 * Wrapper around PouchDB that allows for type-specific hooks and helper functions
 */
export default class DBInterface extends PouchDB {
    currentUser: string;

    constructor(name: string, {currentUser, ...options}: PouchDB.Configuration.DatabaseConfiguration & {currentUser: string}) {
        super(name, options);
        this.currentUser = currentUser;
    }

    // mock schema to use hooks
    mocks: Record<string, SchemaObject> = {
        $document: {
            id: '$document',
            type: 'mock',
            fields: [{id: 'upload', type: 'ListUpload'}]
        }
    }

    hooks: Record<string, Partial<{
        afterGet: (doc: PouchDB.Core.Document<any>) => Promise<void>,
        beforePut: (doc: PouchDB.Core.Document<any>) => Promise<void>,
        beforeDelete: (doc: PouchDB.Core.Document<any>) => Promise<void>,
    }>> = {
        $project: {
            afterGet: async (doc) => {
                const { startDate, endDate } = doc;
                doc.date = [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')];
            },
            beforePut: async (doc) => {
                // data transformation
                doc.startDate = doc.date[0].format('YYYY-MM-DD');
                doc.endDate = doc.date[1].format('YYYY-MM-DD');
                delete doc.date;
            }
        },
        $client: {
            beforeDelete: async (doc) => {
                const { docs } = await this.find({ selector: { link$client: doc._id } });
                if (docs.length !== 0) {
                    throw new ConstraintError(`Please delete the ${docs.length} project${docs.length > 1 ? 's' : ''} before deleting the client.`);
                }
            }
        },
        $document: {
            beforeDelete: async (doc) => {
                const { docs } = await this.find({ selector: { link$document: doc._id } });
                if (docs.length !== 0) {
                    throw new ConstraintError(`There ${docs.length > 1 ? 'are' : 'is'} ${docs.length} project${docs.length > 1 ? 's' : ''} using this document.`);
                }
            }
        }
    }


    /**
     * Merges the hidden propeties of overwriteDoc and originalDOc with each other
     *
     * @param overwriteDoc (will be edited)
     * @param originalDoc (will be edited)
     * @returns wheather overwriteDoc and originalDoc are fully merged (including non hidden properties)
     */
    mergeDocument(overwriteDoc: DBObject, originalDoc: DBObject) {
        originalDoc.$updatedAt = overwriteDoc.$updatedAt;
        originalDoc.$updatedBy = overwriteDoc.$updatedBy;
        let mergeSuccessful = true;

        //Assuming same keys and merge $ properties
        for (let key in overwriteDoc) {
            if (key.includes("$")) {
                if (typeof overwriteDoc[key] !== "object") {
                    if (overwriteDoc[key] !== originalDoc[key]) {
                        throw new Error("Not supported merge two primitives");
                    }
                    continue; //already took the most recent one from clone
                }
                if (_.isArray(overwriteDoc[key])) {
                    if (key === "screenshots") {
                        continue;
                    }
                    overwriteDoc[key] = _.uniqWith([...overwriteDoc[key], ...originalDoc[key]], _.isEqual);
                    originalDoc[key] = _.cloneDeep(overwriteDoc[key]);
                } else {
                    throw new Error("Not Supported Merge two Objects");
                }
            } else if (!key.includes("_")) {
                if (!_.isEqual(overwriteDoc[key], originalDoc[key])) {
                    mergeSuccessful = false;
                }
            }

        }

        /*if ("_attachments" in overwriteDoc){
            doc["_attachments"] = {}
            let attachmentIds = new Set([...Object.keys(overwriteDoc["_attachments"]), ...Object.keys(originalDoc["_attachments"])])
            for (let i in attachmentIds){
                if (overwriteDoc["_attachments"][i]){
                    doc["_attachments"] = {[i]: overwriteDoc["_attachments"][i]}
                } else {
                    doc["_attachments"] = {[i]: originalDoc["_attachments"][i]}
                }
            }
        }*/
        return mergeSuccessful;
    }

    /**
     * Perform pre-/post-processing on a document. Mutates doc and does not return.
     * @param hook Name of stage
     * @param doc Document to process
     * @param schema Schema object that describes document being processed OR containing schema for the document being processed
     */
    async doFieldHooks(hook: 'afterGet' | 'beforePut' | 'beforeDelete', doc: DBObject, schema?: SchemaObject): Promise<void> {
        if (this.hooks[doc.$type]) {
            const func = this.hooks[doc.$type][hook];
            if (func) await func(doc);
        }
        schema = schema ?? this.mocks[doc.$type];
        if (schema) {
            // figure out if type is a part (i.e. a section) of schema
            const thisSchema = findInSchema(schema, doc.$type) ?? schema;
            const fields = flattenSchema(thisSchema, doc.$type.startsWith('template$'));
            for (const field of fields) {
                const Field = getFieldType(field.type);
                if (!(field.id in doc)) {
                    console.warn(`Expected field ${field.id} in document type ${schema.id}`);
                    doc[field.id] = Field.defaultValue;
                }
                const func = Field[hook];
                if (func) await func(doc, field);
            }
        }
    }

    /**
     * Extends PouchDB.get.
     * @param id ID of document to fetch
     * @param schema Schema object that describes document being fetched OR contains the description of the document being fetched
     * @param options PouchDB options
     */
    async smartGet(id: string, {schema, ...options}: Options & PouchDB.Core.GetOptions = {}): Promise<DBObject> {
        let doc = await this.get<DBProps>(id, options);
        await this.doFieldHooks('afterGet', doc, schema);
        return doc;
    }

    /**
     * Extends PouchDB.put.
     * @param doc Document to put
     * @param typeHint Document type (defaults to doc.$type)
     * @param schema Schema definition for doc.$type
     * @param isNew Whether the object is new or not
     * @returns The return `ok` value can be `true` (success), `false` (cancelled), or `update`.
     * If `update`, the client must re-fetch the document.
     */
    async smartPut(doc: DBObject, typeHint?: string, schema?: SchemaObject, isNew?: boolean): Promise<PutResponse> {
        // figure out if doc is new and type of doc
        isNew = isNew ?? !doc._id;
        doc.$type = doc.$type ?? typeHint;
        const isTemplate = doc.$type.startsWith('template$');

        // generate default value
        if (doc.$type in DefaultValues) doc = { ...DefaultValues[doc.$type], ...doc };
        if (schema) doc = { ...generateDefaultValue(schema, isTemplate), ...doc };

        // if this is an add
        if (!doc._id) doc._id = uuidv4();
        if (!doc.$createdAt) doc.$createdAt = Date.now();

        // preprocess the object
        doc.$updatedAt = Date.now();
        doc.$updatedBy = this.currentUser;
        doc._attachments = {}; // only keep attachments claimed by a beforePut hook
        await this.doFieldHooks('beforePut', doc, schema);

        // handle parent-child links
        if (isNew && doc.link$parent) {
            const parent = await this.get<DBProps>(doc.link$parent);
            const ix = 'link$' + doc.$type;
            if (Array.isArray(parent[ix])) {
                parent[ix].push(doc._id);
            } else {
                parent[ix] = doc._id;
            }
            await this.put(parent);
        }

        try {
            return await this.put(doc);
        } catch (e) {
            if (e.name === 'conflict') {
                const latest = await this.smartGet(doc._id, {schema});
                const mergeSuccessful = this.mergeDocument(doc, latest);

                if (mergeSuccessful) {
                    let updatedDoc = await this.put({ ...doc, _rev: latest._rev });
                    return { ...updatedDoc, ok: "update" };
                }

                // @ts-ignore bug with mixing typescript and jsdoc
                const command = await promptConflict({
                    type: 'error',
                    message: 'Database conflict!',
                    description: `There is a newer version of this ${_.lowerCase(typeHint)}. Would you like to overwrite?`,
                    overwrite: doc,
                    discard: latest,
                });
                if (command.action !== 'cancel') {
                    // get the latest _rev and update on top
                    let updatedDoc = await this.put({ ...command.value, _rev: latest._rev });
                    return { ...updatedDoc, ok: "update" };
                } else if (command.action === 'cancel') {
                    return { ok: false, id: doc._id, rev: doc._rev };
                }
            } else {
                throw e;
            }
        }
        return { ok: false, id: doc._id, rev: doc._rev };
    }

    /**
     * Find all children of a certain document.
     * TODO: Optimize. Needs on average O(logn) requests
     */
    async findDeepChildren(doc: DBObject): Promise<DBObject[]> {
        const { docs } = await this.find({ selector: { link$parent: doc._id } });
        const recurse = await Promise.all(docs.map((d) => this.findDeepChildren(d)));
        return _.flatten([docs, ...recurse]);
    }

    /**
     * Delete a document and all its children.
     * TODO: Optimize. Needs on average O(logn) requests
     */
    async smartDelete(docOrId: string | DBObject, {schema, ...options}: Options = {}): Promise<PutResponse> {
        const doc = typeof docOrId === 'string' ? await this.get<DBObject>(docOrId) : docOrId;
        await this.doFieldHooks('beforeDelete', doc, schema);
        try {
            const res = await this.remove(doc, options);

            // force delete all children (only if remove is successful)
            const docs = await this.findDeepChildren(doc);
            await this.bulkDelete(docs.map((doc) => ({ ...doc, _deleted: true })), {schema, ...options});

            return res;
        } catch (e) {
            if (e.name === 'conflict') {
                const latest = await this.smartGet(doc._id);
                // @ts-ignore bug with mixing typescript and jsdoc
                const command = await promptConflict({
                    type: 'error',
                    message: 'Database conflict!',
                    description: `There is a newer version of this ${_.lowerCase(doc.$type)}. Continue with deletion?`,
                    overwrite: {},
                    discard: latest,
                });
                if (command.action === 'overwrite') {
                    // get the latest _rev and use to remove
                    let updatedDoc = await this.remove({ ...doc, _rev: latest._rev });
                    return { ...updatedDoc, ok: "update" };
                } else if (command.action === 'cancel') {
                    return { ok: 'update', id: doc._id, rev: latest._rev };
                } else {
                    return { ok: false, id: doc._id,  rev: doc._rev };
                }
            } else {
                throw e;
            }
        }
    }

    /**
     * Delete all docs in an array. Requires that the docs are populated with _id and _rev.
     */
    async bulkDelete(docs: DBObject[], {schema, ...options}: Options = {}): Promise<(PouchDB.Core.Response | PouchDB.Core.Error)[]> {
        for (const doc of docs) {
            doc._deleted = true;
            await this.doFieldHooks('beforeDelete', doc, schema);
        }
        return await this.bulkDocs(docs, options);
    }


    /**
     * Find documents according to a query (simple wrapper, used for type correctness)
     */
    find(options: PouchDB.Find.FindRequest<DBObject>): Promise<PouchDB.Find.FindResponse<DBObject>> {
        return super.find(options) as Promise<PouchDB.Find.FindResponse<DBObject>>;
    }

    /**
     * Find documents according to a query (simple wrapper, used for type correctness)
     */
    async query(fun: string | PouchDB.Map<DBObject, DBObject> | PouchDB.Filter<DBObject, DBObject>,
          opts?: PouchDB.Query.Options<DBObject, DBObject> | PouchDB.Core.Callback<PouchDB.Query.Response<DBObject>>):
        Promise<PouchDB.Query.Response<DBObject>> {
        if (typeof opts === 'function') opts = {};
        return await super.query(fun, opts) as PouchDB.Query.Response<DBObject>;
    }
}

/**
 * Type of object automatically added
 */
export const DefaultValues: Record<string, Record<string, any>> = {
    $client: {
        contactName: '',
        contactEmail: '',
        companyName: '',
        companyWebsite: '',
    },
    $project: {
        title: '',
        status: 'incomplete',
        stakeholders: [],
    },
    $document: {
        name: '',
        link$schema: null,
        upload: [],
    }
}
