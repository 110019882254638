import React, {useState} from 'react';
import _ from 'lodash';
import {useHistory} from "react-router-dom";
import {useDB} from '../../contexts/database';

import { useTranslation } from 'react-i18next';
import {AutoComplete, Input} from 'antd';
import {useProjectSchemas} from "../../common/schema";

const renderItem = (result) => ({
    value: result.id,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {result.title}
        <span>{result.description}</span>
      </div>
    ),
  });

export const GlobalSearch = () => {
    const db = useDB();
    const { t } = useTranslation('navigation');
    const [data, setData] = useState({});
    const history = useHistory();
    const [value, setValue] = useState('');
    const schemas = useProjectSchemas();

    const handleSearchChange = (query) => {
        const regex = new RegExp(query, 'i');
        // search schemas
        const schemaMatches = Object.keys(_.pickBy(schemas, (schema) => regex.test(schema.title)));
        db.find({
            selector: {
                $or: [
                    {'\\$type': '$client'},
                    {'\\$type': '$project'}
                ]
            },
            fields: ['_id', '$type', 'companyName', 'contactName', 'title', 'link$schema']
        }).then(data => {
            setData(data.docs.filter((doc) => (
                schemaMatches.includes(doc.link$schema) ||
                ['companyName', 'contactName', 'title'].some((field) => regex.test(doc[field]))
            )).map(item => ({
                id: item._id,
                type: item.$type,
                title: item.companyName || item.title,
                description: item.contactName || schemas[item.link$schema].title,
            })));
        })
        .catch(console.error);
    };

    const handleResultSelect = (id) => {
        const clicked = _.find(data, ['id', id]);
        history.push(`/app/${clicked.type.substring(1)}/${clicked.id}`);
        setValue('');
    }

    const clients = _.filter(data, ['type', '$client']);
    const projects = _.filter(data, ['type', '$project']);

    const options = [];

    if (clients.length) {
        options.push({
            label: <span>Clients</span>,
            options: clients.map(renderItem),
        });
    }

    if (projects.length) {
        options.push({
            label: <span>Projects</span>,
            options: projects.map(renderItem),
        });
    }

    return <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={500}
        style={{ width: '30em' }}
        options={options}
        onSearch={_.debounce(handleSearchChange, 500, {trailing: true})}
        value={value}
        onChange={setValue}
        onSelect={handleResultSelect}
    >
        <Input.Search
            enterButton
            allowClear
            placeholder={t("search")} />
    </AutoComplete>;
};
