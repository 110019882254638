import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Input, message, Popover, Row, Space, Table} from "antd";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import DeleteButton from "./DeleteButton";
import _ from "lodash";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Autofill from "../fields/Autofill";
import { useTranslation } from 'react-i18next';

// TODO: better UX for adding rows (adding to bottom of infinitely long table doesn't make sense)
// TODO: ensure scrolling is always possible to trigger InfiniteScroll
const PAGE_SIZE = 100;
const isInternalTableField = (val, key) => key.startsWith('$$');

/**
 * HOC wrapper for infinite-scrolling table
 *
 * Fixed data can be provided using the `data` parameter. If `data` is specified, `onFilter` is required.
 * Dynamic data is sourced by calling `fetchData({filter, order, limit, cursor})` following PouchDB's query language.
 * Adding is handled internally or by `onAdd` if provided.
 * Pass `inlineEditable` and `onSave` to activate inline editing.
 * Enable drag-and-drop by passing `dragAndDrop`. This disables sorting.
 *
 * Pass `rowNumbering=automatic` to get automatic row numbers or `rowNumbering=manual` to read off of `data[].$$rowNumber`
 * @returns {JSX.Element}
 * @constructor
 */
const InfiniteTable = ({
    data: initialData, fetchData, columns, inlineEditable, dragAndDrop, selectable = true,
    onRowClick, onAdd, onDelete, onEdit, onSave, onDragAndDrop, onFilter, onQuickAdd,
    rowNumbering,
    baseValue = {}, ...tableProps
}) => {
    // This component must do a lot of things:
    // 1. Data fetching
    // 2. Row numbering
    // 3. Selectable rows
    // 4. Editable rows
    // 5. Drag-and-drop
    // 6. Row addition

    //
    // DATA FETCHING
    //
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(0);
    const [filter, setFilter] = useState(null);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const {t} = useTranslation('components');

    // fetches the next page of data and displays it (optionally clearing cached data)
    // always pass `reset` if the currently-shown entries need to be redrawn (i.e. after sort/search)
    const query = async (args, reset = false) => {
        if (dragAndDrop) args.order = null;
        if (editingKey !== -1 && reset) {
            message.error('Please save or discard changes before modifying the table view');
            return;
        }

        // switch depending on data source: static or dynamic
        setLoading(true);
        if (fetchData) {
            // fetch data using provided function
            const next = await fetchData(args);
            const newData = reset ? next : [...data, ...next];
            setData(newData);
            setVisible(newData.length);
            if (next.length === 0) setHasMore(false);
            if (reset) setHasMore(true);
        } else {
            // handle data by manipulating initialData
            if (reset) {
                let newData = initialData;
                if (args.order) {
                    const [sortCol, sortDir] = Object.entries(args.order)[0];
                    newData = _.sortBy(newData, sortCol);
                    if (sortDir === 'desc') newData = newData.reverse();
                }
                if (args.filter && onFilter) {
                    newData = newData.filter(onFilter(args.filter));
                }
                setData(newData);
                setVisible(args.limit);
            } else if (data.length >= visible) {
                setVisible(visible + args.limit);
            } else {
                setHasMore(false);
            }
        }
        setLoading(false);
    }

    // initial load
    useEffect(() => {
        query({limit: PAGE_SIZE}, true).catch(console.error);
    }, []);

    // updated static data source
    useEffect(() => {
        if (initialData) {
            setHasMore(visible < initialData.length);
            setVisible(Math.min(Math.max(PAGE_SIZE, visible), initialData.length));
            setData(initialData);
        }
    }, [initialData]);

    // event handler for top search bar
    const handleSearch = (search, e) => {
        e.preventDefault();
        const nextFilter = new RegExp(search, 'i');
        setFilter(nextFilter);
        query({filter: nextFilter, order, limit: PAGE_SIZE}, true).catch(console.error);
    }

    // event handler for changing sort direction
    const handleTableChange = (pagination, filters, sorter) => {
        const {columnKey: column, order: direction} = sorter;
        if (column) {
            const nextOrder = {[column]: direction === 'ascend' ? 'asc' : 'desc'};
            setOrder(nextOrder);
            query({filter, order: nextOrder, limit: PAGE_SIZE}, true).catch(console.error);
        }
    }

    // event handler for scrolling to bottom of table
    const handleInfiniteOnLoad = () => {
        const args = {filter, limit: PAGE_SIZE};
        if (order) args.order = order;
        args.skip = data.length;
        query(args).catch(console.error);
    }

    tableProps.dataSource = data.map((doc, i) => ({...doc, $$key: i})).slice(0, visible) ?? [];
    tableProps.loading = loading;
    tableProps.pagination = false;
    tableProps.onChange = handleTableChange;

    //
    // ROW NUMBERING
    //

    if (rowNumbering) columns = [{key: '$$rowNumber', title: '#', dataIndex: '$$rowNumber'}, ...columns];
    if (rowNumbering === 'automatic') {
        tableProps.dataSource = tableProps.dataSource.map((row, i) => ({...row, $$rowNumber: i + 1}));
    }

    //
    // SELECT AND DELETE
    //
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    tableProps.rowKey = '$$key';

    // event handler for delete button (only shown if selectable === true)
    const handleDelete = async (keys) => {
        try {
            if (onDelete) await onDelete(keys.map((i) => data[i]), keys);
            // if we are not a controlled component, update state internally
            // otherwise, the onDelete event handler should update through initialData
            if (!initialData) setData((data) => data.filter((row, i) => !keys.includes(i)));
            setSelectedRowKeys([]);
            message.success('Successfully deleted.');
        } catch (e) {
            console.error(e);
            message.error(`Failed to delete: ${e.message}`);
        }
    }

    if (selectable) {
        tableProps.rowSelection = tableProps.rowSelection ?? {};
        tableProps.rowSelection = {
            type: 'checkbox',
            onChange: (next) => {
                setSelectedRowKeys(next);
                console.log(next);
            },
            selectedRowKeys,
            ...tableProps.rowSelection
        };
    }

    //
    // INLINE EDITING
    //
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState(-1);
    // we can only edit if (1) there are editable columns (2) we can save and (3) explicitly enabled
    const editable = _.some(columns, 'editable') && onSave && inlineEditable;

    // check if row is the actively edited row
    const isEditing = (row) => row.$$key === editingKey;

    // edit a row (and save if there is another row being edited)
    const edit = async (row) => {
        if (editingKey !== -1) await save();
        setEditingKey(row.$$key);
        // notify parent component that editing row has changed
        if (onEdit) onEdit(row);
        form.setFieldsValue(_.omit(row, isInternalTableField));
    };

    const cancel = () => {
        form.resetFields();
        setEditingKey(-1);
    }

    // save a row by merging with existing data and notifying parent
    // onSave must return the row in its latest form (e.g. with latest _rev)
    const save = async () => {
        const value = form.getFieldsValue(Object.keys(data[editingKey]));
        const nextValue = await onSave({key: editingKey, value}).catch((e) => {
            console.error(e);
            message.error('Failed to save. Please try again.');
        });
        if (nextValue !== null) {
            setData((data) => {
                data[editingKey] = nextValue;
                return data;
            });
            // only stop editing if the save was successful
            cancel();
        }
    }

    if (editable) {
        columns = columns.map((col) => {
            if (col.editable) {
                // editable components receive editing state and context
                //   these props are passed to EditableCell (via components.body.cell)
                col.onCell = (row) => ({
                    record: row,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(row),
                    context: {form, col},
                });
            }

            return col;
        });
        columns.push({
            key: 'actions',
            title: null,
            width: 75,
            render: (_, row) => isEditing(row) ? (
                <Space>
                    <Button onClick={() => save()} size="small" type="primary">
                        <SaveOutlined />
                    </Button>
                    <Button onClick={cancel} size="small" type="primary" danger>
                        <CloseOutlined />
                    </Button>
                </Space>
            ) : (
                <Space className="show-on-hover">
                    <Button onClick={() => edit(row)} size="small">
                        <EditOutlined />
                    </Button>
                    <Button onClick={() => handleDelete([row.$$key])} size="small" danger>
                        <DeleteOutlined />
                    </Button>
                </Space>
            )
        });

        tableProps.components = tableProps.components ?? {};
        tableProps.components.body = tableProps.components.body ?? {};
        tableProps.components.body.cell = EditableCell;
        tableProps.rowClassName = (tableProps.rowClassName ?? "") + " editable-row";
    }

    //
    // DRAG AND DROP
    //
    if (dragAndDrop) {
        // rearranging values
        const moveRow = (dragIndex, hoverIndex) => {
            onDragAndDrop(dragIndex, hoverIndex)
                .then(next => {
                    setData(next);
                    // update active row
                    setEditingKey((editingKey) => {
                        if (dragIndex === editingKey) return hoverIndex;
                        if (hoverIndex === editingKey) return hoverIndex + 1;
                        return editingKey;
                    });
                })
                .catch(console.error);
        };

        tableProps.onRow = (record, index) => ({index, moveRow});
        tableProps.components = tableProps.components ?? {};
        tableProps.components.body = tableProps.components.body ?? {};
        tableProps.components.body.row = DraggableBodyRow;
    }

    //
    // ROW ADDITION
    //

    // event handler for add row button
    //   managed internally if no onAdd handler
    //   if possible, hop into editing the new row
    const handleAdd = () => {
        let newRow = null;
        if (onAdd) {
            newRow = onAdd();
            if (newRow) newRow.$$key = data.length;
        } else {
            // baseValue can either be static (i.e. object) or dynamic (i.e. function)
            // this allows for things like UUIDs and time-based fields
            let defaults = baseValue;
            if (_.isFunction(baseValue)) defaults = baseValue();
            newRow = {...defaults, $$key: data.length};
            // modify data functionally due to weird race condition with the setData in save (called by edit)
            setData((data) => {
                data.push(newRow);
                return data;
            });
            setVisible(visible + 1);
        }

        if (editable && newRow) edit(newRow);
    }

    // event handler for quick add button
    //   note that we do not hop into editing
    const handleQuickAdd = (template) => {
        // return a row if the row must be manually handled
        onQuickAdd(template)
            .then((row) => {
                if (row) {
                    let defaults = baseValue;
                    if (_.isFunction(baseValue)) defaults = baseValue();
                    const newRow = {...defaults, ...row, $$key: data.length};
                    setData((data) => {
                        data.push(newRow);
                        return data;
                    })
                    setVisible(visible + 1);
                } else {
                    message.success('Entry added');
                    return query({limit: data.length}, true);
                }
            })
            .catch((e) => {
                message.error('Quick add failed');
                console.error(e);
            });
    }

    //
    // MISC
    //

    // event handler for row click: merge row props with antd onRow prop
    if (tableProps.onRow && onRowClick) {
        const oldOnRow = tableProps.onRow;
        tableProps.onRow = (row) => ({...oldOnRow(row), onClick: () => onRowClick(row)});
    } else if (onRowClick) {
        tableProps.onRow = (row) => ({onClick: () => onRowClick(row)});
    }

    // indicate end of table with greyed out footer
    if (!hasMore && !loading) {
        tableProps.footer = () => '';
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Form form={form} component={false}>
                <Row gutter={[24, 16]}>
                    <Col flex="auto">
                        <Input.Search placeholder={t("filter")} enterButton allowClear onSearch={handleSearch} />
                    </Col>
                    <Col>
                        <Space>
                            <Button icon={<PlusOutlined/>} type="primary" shape="round" onClick={handleAdd}>
                                {t("buttons.add")}
                            </Button>
                            {onQuickAdd &&
                                <Popover trigger="click" content={
                                    <Autofill.Editor
                                        onTemplate={handleQuickAdd}
                                        autoFocus
                                        style={{width: '200px'}}
                                        onChange={_.noop}
                                        field={{id: '$dummy', type: 'Autofill', confirm: false}}
                                    />
                                }>
                                    <Button icon={<PlusOutlined/>} type="primary" shape="round">
                                        Quick Add
                                    </Button>
                                </Popover>
                            }
                            {selectable &&
                                <DeleteButton
                                    disabled={selectedRowKeys.length === 0}
                                    onConfirm={() => handleDelete(selectedRowKeys)}
                                />}
                        </Space>
                    </Col>
                    <Col span={24}>
                        <InfiniteScroll loadMore={handleInfiniteOnLoad} hasMore={!loading && hasMore}>
                            <Table columns={columns} {...tableProps} />
                        </InfiniteScroll>
                    </Col>
                </Row>
            </Form>
        </DndProvider>
    )
}

/**
 * HOC for enabling in-place editing on {@link Table}.
 * @see https://ant.design/components/table/#components-table-demo-edit-row
 */
const EditableCell = ({editing, dataIndex, title, children, context, record, ...restProps}) => {
    const FieldInput = context?.col?.fieldComponent ?? Input;
    const itemProps = context?.col?.onFormItem ?? {};
    const fieldProps = context?.col?.onField ?? {};
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    fieldKey={dataIndex}
                    style={{margin: 0}}
                    {...itemProps}
                >
                    <FieldInput form={context.form} {...fieldProps} />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const TYPE = 'DraggableBodyRow';

/**
 * HOC for enabling drag-and-drop on {@link Table}.
 * @see https://ant.design/components/table/#components-table-demo-drag-sorting
 */
const DraggableBodyRow = ({ index, moveRow, className, style, record, ...restProps }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: TYPE,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            if (!_.isNil(item.index)) {
                moveRow(item.index, index);
            }
        },
    });
    const [, drag] = useDrag({
        type: TYPE,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

export default InfiniteTable;
