import _ from "lodash";

const nargs = /{{([^{}]+)}}/gm;

/**
 * Simple string formatter.
 *
 * Pass a dictionary to use named replacements (e.g. format("{{a}} {{b}}", {a: 1, b: 2}) => "1 2")
 * or pass nargs to use indexed replacements (e.g. format("{{0}} {{1}}", 1, 2) => "1 2").
 *
 * Named replacements can access nested elements (e.g. format("{{a.b.c[1]}}", {a: {b: {c: [1, 2]}}}) => "2").
 *
 * @param string
 * @returns {string}
 */
export function format(string) {
    let args;

    if (arguments.length === 2 && typeof arguments[1] === "object") {
        args = arguments[1]
    } else {
        args = new Array(arguments.length - 1);
        for (let i = 1; i < arguments.length; ++i) {
            args[i - 1] = arguments[i]
        }
    }

    if (!args || !args.hasOwnProperty) {
        args = {}
    }

    return string.replace(nargs, function replaceArg(match, i, index) {
        let result;

        if (string[index - 1] === "{" &&
            string[index + match.length] === "}") {
            return i
        } else {
            result = _.property(i)(args) ?? null;
            if (result === null || result === undefined) {
                return ""
            }

            return result
        }
    })
}

export const variables = (string) => {
    let m;
    let s = new Set();

    while ((m = nargs.exec(string)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === nargs.lastIndex) {
            nargs.lastIndex++;
        }

        if (string[m.index - 1] === '{' && string[nargs.lastIndex] === '}')
            continue;
        // The result can be accessed through the `m`-variable.
        s.add(m[1])
    }

    return Array.from(s);
};
