import React, {useEffect} from "react";
import {Input} from "antd";
import {useMsal} from "@azure/msal-react";

// TODO: make this cache less jank
let cachedIP = null;
const getIP = async () => {
    if (!cachedIP) {
        const res = await fetch('https://ipecho.net/plain');
        cachedIP = await res.text();
    }
    return cachedIP;
}

const SimpleText = ({value, onChange, ...props}) => {
    // intercept props
    const {accounts} = useMsal();
    const name = accounts[0].name;
    useEffect(() => {
        const replacements = [
            {key: '$user', replacer: async () => name},
            {key: '$ip', replacer: getIP}
        ]
        if (value) {
            let nextValue = value;
            Promise.all(replacements.map(async ({key, replacer}) => {
                if (nextValue.includes(key)) {
                    nextValue = nextValue.replace(key, await replacer())
                }
            }))
                .then(() => onChange(nextValue))
                .catch(() => {}); // ignore errors (any update of value will make it try again anyways)
        }
    }, [name, value, onChange])
    return <Input {...props} onChange={onChange} value={value} />
}

export default {Editor: SimpleText, Viewer: ({value}) => <>{value}</>, defaultValue: ''};
