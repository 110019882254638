import React, {useEffect, useRef, useState} from "react";
import {Card, Carousel, Col, Divider, Form, Image, Input, List, Popconfirm, Radio, Row, Upload} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import {v4 as uuid} from "uuid";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import update from "immutability-helper";
import _ from "lodash";
import {useDB} from "../../contexts/database";

const TYPE = 'DraggableCard';

const DraggableCard = ({field, onRemove, onDragDrop, documentId}) => {
    const {name, key, fieldKey, ...rField} = field;
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: TYPE,
        collect: monitor => {
            const { key: dragIndex } = monitor.getItem() || {};
            if (dragIndex === key) return {};
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < key ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            if (!_.isNil(item.key)) {
                onDragDrop(item.key, key);
            }
        },
    });
    const [, drag] = useDrag({
        type: TYPE,
        item: { key },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <div ref={ref} className={`draggable-card ${isOver ? dropClassName : ''}`}>
            <Card
                cover={
                    <Form.Item {...rField} noStyle name={[name, 'id']} fieldKey={[fieldKey, 'id']}>
                        <BlobImage documentId={documentId} />
                    </Form.Item>
                }
                actions={[<Popconfirm title={'Are you sure?'} onConfirm={onRemove}><DeleteOutlined /></Popconfirm>]}
            >
                <Form.Item {...rField} name={[name, 'caption']} fieldKey={[fieldKey, 'caption']} style={{marginBottom: 0}}>
                    <Input />
                </Form.Item>
            </Card>
        </div>
    );
}

/**
 * A wrapper for antd.Image that renders image blobs
 * TODO: make the image editable
 * @param blob
 * @returns {JSX.Element}
 * @constructor
 */
const BlobImage = ({value, documentId, ...props}) => {
    const [url, setUrl] = useState();
    const db = useDB();

    useEffect(() => {
        db.getAttachment(documentId, value, { blob: true }).then((blob) => {
            setUrl(URL.createObjectURL(blob));
        });

        return function cleanup() {
            URL.revokeObjectURL(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return <Image {...props} src={url} />;
};

const ImageUpload = ({form, field}) => {
    const db = useDB();
    const docId = form.getFieldValue('_id');
    const [view, setView] = useState(3);

    const handleSelect = async (add, {onSuccess, onError, file}) => {
        const id = uuid();

        try {
            let attachment = await db.putAttachment(
                docId,
                id,
                form.getFieldValue('_rev'),
                file,
                file.type
            )

            form.setFieldsValue({
                _rev: attachment.rev,
                isNew: ''
            })
        } catch(e) {
            return onError(e);
        }

        onSuccess();

        add({id, caption: ''});
    }

    const handleDragDrop = (dragIndex, hoverIndex) => {
        const values = form.getFieldValue(field.id);
        const dragRow = values[dragIndex];
        const next = update(values, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        });
        form.setFieldsValue({[field.id]: next});
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Form.List name={field.id}>
                {(fields, {add, remove}) => (
                    <>
                        <Row>
                            <Col span={24}>
                                <Upload.Dragger
                                    accept="image/jpeg, image/png"
                                    customRequest={handleSelect.bind(null, add)}
                                    multiple
                                    showUploadList={false}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag files to this area to upload</p>
                                    <p className="ant-upload-hint">Supports PNG and JPEG files</p>
                                </Upload.Dragger>
                            </Col>
                        </Row>
                        <Divider orientation="right">
                            <Radio.Group
                                options={[{label: 'Large', value: 1}, {label: 'Small', value: 3}, {label: 'Thumbnail', value: 6}]}
                                value={view}
                                onChange={(e) => setView(e.target.value)}
                                optionType="button"
                            />
                        </Divider>
                        <Row>
                            <Col span={24}>
                                <Image.PreviewGroup>
                                    <List
                                        grid={{gutter: 0, column: view}}
                                        className={"draggable-horizontal"}
                                        dataSource={fields}
                                        renderItem={(field) => (
                                            <List.Item>
                                                <DraggableCard
                                                    field={field}
                                                    documentId={docId}
                                                    onRemove={() => remove(field.key)}
                                                    onDragDrop={handleDragDrop}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Image.PreviewGroup>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>
        </DndProvider>
    )
};

const ImageViewer = ({value, compact = false}) => {
    if (!value || value.length === 0) return <em>Please fill this in!</em>;
    if (compact) {
        if (value.length === 1) return value[0].caption ?? <em>Missing caption!</em>;
        return `${value[0].caption} (+${value.length - 1} more)`;
    }

    return (
        <div style={{width: 'calc(100% - 50px)', marginLeft: '25px'}}>
            <Image.PreviewGroup>
                <Carousel
                    infinite={false}
                    arrows nextArrow={<ArrowRightOutlined />} prevArrow={<ArrowLeftOutlined />}
                >
                    {value.map((value, i) => (
                        <div key={i}>
                            <BlobImage value={value.id} documentId={value.doc} />
                            {value.caption}
                        </div>
                    ))}
                </Carousel>
            </Image.PreviewGroup>
        </div>
    )
}

function blobToBase64(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

const fetchAttachments = async (value) => {
    return Promise.all(value.map(async (img) => {
        img.blob = await window.db.getAttachment(img.doc, img.id);
        return img;
    }));
}

export default {
    Editor: ImageUpload,
    Viewer: ImageViewer,
    defaultValue: [],
    stringify: async (value) => {
        return Promise.all((await fetchAttachments(value)).map(async (img) => {
            return {
                caption: img.caption,
                src: await blobToBase64(img.blob)
            }
        }));
    },
    beforePut: (doc, field) => {
        doc[field.id].map((img) => {
            doc._attachments[img.id] = { stub: true };
            delete img.doc;
            return img;
        });
    },
    afterGet: (doc, field) => {
        doc[field.id] = doc[field.id].map((img) => {
            img.doc = doc._id;
            return img;
        });
    },
    attachments: {
        getBlob: async (files) => {
            return (await fetchAttachments(files)).map(file => file.blob);
        },
        defaultFileName: '{{item.caption}}.{{extension}}'
    }
};
