import React, {useContext} from "react";
import {useDB} from "./database";
import {format} from "../lib/string-template";

// Store the current project and section schema, along with a flag for if we are in a template
const SchemaContext = React.createContext<{schema?: ProjectSchema, section?: SectionSchema, template?: boolean}>({});
// Store the current *autofill configuration*.
// This is important if we have a collection tab (with autofill) -> simple tab (no autofill) -> Autofill field,
// as then SchemaContext will store the simple tab and lose the autofill configuration.
export const AutofillContext = React.createContext<SectionSchema | null>(null);

export const useSchema = () => useContext(SchemaContext);
export const SchemaContextProvider: React.ComponentType<{value: {schema: ProjectSchema, section: SectionSchema, template: boolean}}> = ({value, children}) => {
    const node = <SchemaContext.Provider value={value}>{children}</SchemaContext.Provider>
    if (value.section?.autofill) return <AutofillContext.Provider value={value.section}>{node}</AutofillContext.Provider>;
    return node;
}

export const useAutofillFunctions: () => {query: (search: string) => Promise<AntOption[]>, select: (res: string) => Promise<any>} = () => {
    const db = useDB();
    const {schema} = useSchema();
    const autofillSection = useContext(AutofillContext);
    const error = {
        query: async () => [{value: '', label: 'Autofill not implemented yet'}],
        select: async () => 'Autofill not implemented yet',
    }
    const autofill = autofillSection?.autofill;
    if (!autofillSection || !autofill || !schema) return error;
    switch (autofill.type) {
        case "template":
            return {
                query: async (search) => {
                    const {titleColumn} = autofill;
                    const searchFields = autofillSection.searchFields ?? [titleColumn];
                    const query: PouchDB.Find.FindRequest<DBObject> = {
                        selector: {'\\$type': `template$${schema.id}$${autofillSection.id}`},
                        fields: ['_id', titleColumn],
                        limit: 5
                    };
                    if (search) query.selector.$or = searchFields.map(col => ({[col]: {$regex: search}}));

                    const data = await db.find(query);
                    return data.docs.map((item) => ({
                        label: item[titleColumn],
                        value: item._id,
                    })) as AntOption[];
                },
                select: async (id) => await db.smartGet(id, {schema: autofillSection}),
            }
        case "api":
            return {
                query: async (search) => {
                    const nextOptions: AntOption[] = [];
                    const {apis} = autofill;
                    await Promise.all(apis.map(async (api) => {
                        const match = search.match(new RegExp(api.regex));
                        if (match) {
                            const url = format(api.url, {match});
                            const res = await fetch(url, {referrerPolicy: 'no-referrer'});
                            if (res.status === 200) {
                                const data = await res.json();
                                nextOptions.push({
                                    label: format(api.title, {match, data}),
                                    // serialize since we need multiple fields in one string
                                    value: format(JSON.stringify(api.value), {match, data}),
                                });
                            }
                        }
                    }));
                    return nextOptions;
                },
                select: async (serialized) => JSON.parse(serialized),
            }
        default:
            return error;
    }
}
