import React, {useCallback, useEffect} from "react";
import {useRef, useState} from "react";
import {format, variables} from "../../lib/string-template";
import {Editor} from '@toast-ui/react-editor';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Row, Col, Input, Form, Button, Drawer} from 'antd';
import ReactMarkdown from "react-markdown";
import {useSchema} from "../../contexts/schema";

const RichText = ({onChange, value, required = false}) => {
    const [form] = Form.useForm();
    const {template} = useSchema();
    const [variableNames, setVariableNames] = useState([]);
    const [visible, setVisible] = useState(false);
    const internalRef = useRef();

    const getEditor = () => {
        return internalRef.current;
    }

    const handleMarkdownChange = useCallback(() => {
        const editor = getEditor();
        let newData = editor.getMarkdown();
        if (!template) {
            const variableNames = variables(newData);
            setVariableNames(variableNames);
            newData = format(newData, form.getFieldsValue(variableNames));
        }
        if (newData !== value) {
            onChange(newData);
        }
    }, [form, onChange, template, value]);

    const handleRef = (e) => {
        internalRef.current = e?.getInstance?.();
    }

    const toggleDrawer = () => {
        if (visible) handleMarkdownChange();
        setVisible(!visible);
    }

    useEffect(() => {
        const editor = getEditor();
        const data = editor.getMarkdown();
        // only overwrite new value when it is different **with respect to variables**
        if (format(data, form.getFieldsValue()) !== value) {
            editor.setMarkdown(value);
            handleMarkdownChange();
        }
    }, [value, form, handleMarkdownChange]);

    return (
        <>
            <Row>
                <Col
                    span={24}
                    style={{
                        position: 'relative',
                        overflow: 'hidden',
                        border: '1px #ccc solid'
                    }}
                >
                    <Editor
                        previewStyle="tab"
                        initialEditType="markdown"
                        onChange={handleMarkdownChange}
                        ref={handleRef}
                        required={required}
                        usageStatistics={false}
                    />
                    <Form
                        form={form}
                        component={false}
                        layout="vertical"
                        onFinish={toggleDrawer}
                        size="small"
                    >
                        {(!template && variableNames.length > 0) &&
                            <>
                                <Button type="link" style={{position: 'absolute', bottom: 0, left: 0}} size={'small'}
                                        onClick={toggleDrawer}>Edit Variables</Button>
                                <Drawer
                                    title="Variables"
                                    placement="right"
                                    closable={false}
                                    visible={visible}
                                    onClose={toggleDrawer}
                                    getContainer={false}
                                    style={{
                                        position: 'absolute'
                                    }}
                                >
                                    {variableNames.map((v, i) => (
                                        <Form.Item key={i} name={v} required>
                                            <Input placeholder={v}/>
                                        </Form.Item>
                                    ))}
                                    <Form.Item style={{textAlign: 'right'}} size={'small'}>
                                        <Button onClick={form.submit} type={'primary'}>Close</Button>
                                    </Form.Item>
                                </Drawer>
                            </>
                        }
                    </Form>
                </Col>
            </Row>
        </>
    );
}

const Viewer = ({value, compact = false}) => {
    if (!value) return <em>Please fill this in!</em>
    if (compact) {
        // render only one line
        const lines = value.split('\n');
        if (lines.length > 1) value = lines[0];
        // render only textual elements (see https://github.com/remarkjs/react-markdown/issues/42)
        return (
            <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '20em'}}>
                <ReactMarkdown
                    source={value}
                    allowedTypes={['text', 'strong', 'delete', 'emphasis', 'link']}
                    unwrapDisallowed
                    escapeHtml
                />
            </div>
        )
    }
    return <ReactMarkdown source={value} escapeHtml={true} />;
}

export default {Editor: RichText, Viewer, defaultValue: ''};
