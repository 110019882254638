import {message} from "antd";
import {v4 as uuidv4} from "uuid";
import React, {useEffect, useState} from "react";
import InfiniteTable from "./InfiniteTable";
import _ from "lodash";
import {useDB} from "../../contexts/database";
import {useSchema} from "../../contexts/schema";

/**
 * HOC wrapper on {@link InfiniteTable} for fetching from DB
 *
 * Searches database for `type`.
 * If `queryIndex` is specified, use `queryIndex` to find "type/queryParam".
 * Includes search regex-matches `searchFields`.
 * The `baseValue` is augmented with a `type` and UUID `_id`.
 * @see InfiniteCards
 * @returns {JSX.Element}
 * @constructor
 */
const DBTable = ({type, queryParam, queryIndex, fields, searchFields, baseValue, ...baseProps}) => {
    const db = useDB();
    const fieldNames = fields ?? baseProps.columns.map(col => col.dataIndex);
    const {schema} = useSchema();

    // TODO: find a way to allow sorting + pagination with remote database and non-admin access
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            // pick an index
            const index = queryIndex ?? 'by_type';
            const startkey = queryParam ? `${queryParam}/${type}` : type;
            const res = await db.query(
                `map/${index}`,
                {startkey, endkey: startkey + '\ufff0', include_docs: true}
            );
            let docs = res.rows.map(({doc}) => doc);
            docs = _.sortBy(docs, '$createdAt');
            await Promise.all(docs.map((doc) => db.doFieldHooks('afterGet', doc, schema)));
            docs.forEach((doc, index) => doc.$$rowNumber = index + 1 ?? '-');
            setData(docs);
        }
        fetchData().catch(console.error);
    }, [type]);

    const handleEdit = (row) => {
        // handle conflicts in the database (if the user wants to)
        db.smartGet(row._id).catch((e) => {
            // when creating row, not_found will throw
            if (e.name !== 'not_found') console.error(e);
        });
    }

    const handleDelete = async (docs, keys) => Promise.all(docs.map((doc) => db.smartDelete(doc)));

    const handleSave = async ({value}) => {
        const res = await db.smartPut(value, type);
        if (res.ok === 'update') {
            return await db.smartGet(value._id);
        } else if (res.ok) {
            value._id = res.id;
            value._rev = res.rev;
            message.success('Entry saved.');
        }
        return value;
    }

    const handleFilter = (query) => (doc) => _.some(searchFields, (key) => {
        const isStr = _.isString(doc[key]);
        if (!isStr) console.warn(`Using JSON stringify on field ${key} for search`);
        return query.test(isStr ? doc[key] : JSON.stringify(doc[key]));
    });

    return <InfiniteTable
        data={data}
        onEdit={handleEdit}
        onSave={handleSave}
        onDelete={handleDelete}
        onFilter={handleFilter}
        fields={fieldNames}
        baseValue={() => ({
            // we include _rev=undefined so that if a new entry gets a _rev while editing
            // (e.g. due to ListUpload), there exists a form key _rev that can be updated
            _id: uuidv4(), _rev: undefined, $type: type, ...baseValue
        })}
        rowNumbering="manual"
        {...baseProps}
    />;
}

export default DBTable;
