import {Form, Radio, Tooltip, Space} from 'antd';
import {InfoCircleOutlined} from "@ant-design/icons";
import React from "react";

export const ButtonChoiceGroup = (
    {
        choices = [],
        onChange = console.log,
        buttonStyle = 'solid',
        size = 'small',
        name,
        value,
        ...props
    }
) => {

    return (
        <Radio.Group {...props} buttonStyle={buttonStyle} size={size} value={value} onChange={onChange}>
            {
                choices.map(({label, value, tooltip}, i) => {
                    return (
                            tooltip
                            &&
                            <Tooltip key={i} title={tooltip} mouseEnterDelay={1}>
                                <Radio.Button value={value}>{label}</Radio.Button>
                            </Tooltip>
                        )
                        ||
                        <Radio.Button key={i} value={value}>{label}</Radio.Button>;
                })
            }
        </Radio.Group>
    )
}

export const ButtonGroupFormField = ({label, tooltip, name, choices}) => {
    return (
        <Form.Item
            name={name}
            label={
                <Space>
                    {label}
                    {
                        tooltip &&
                        <Tooltip title={tooltip} mouseEnterDelay={1}>
                            <InfoCircleOutlined/>
                        </Tooltip>
                    }
                </Space>
            }
        >
            <ButtonChoiceGroup choices={choices}/>
        </Form.Item>
    );
}