import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./contexts/MSALContext";
import {BrowserRouter} from "react-router-dom";
import i18n from './i18n';
import DatabaseProvider from './contexts/database';
import {I18nextProvider} from "react-i18next";

render(
    <BrowserRouter>
        <AuthProvider>
            <DatabaseProvider>
                <I18nextProvider i18n={i18n}>
                    <App/>
                </I18nextProvider>
            </DatabaseProvider>
        </AuthProvider>
    </BrowserRouter>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
