import React from 'react';
import { Button, Modal, notification, Space } from 'antd';
import DiffViewer from '../components/elements/DiffViewer';

import { useTranslation } from 'react-i18next';

/**
 * Display a notification prompting for a confirmation.
 * Resolves to `true` only if the confirm button is pressed, `false` otherwise
 * 
 * @param type {string} Icon to add to notification
 * @param message {string} Notification header
 * @param description {string} Notification body
 * @param confirm {string} Confirm button text
 * @returns {Promise<boolean>}
 */
export const promptConfirm = ({ type = 'open', message, description, confirm = 'confirm' }) =>
    new Promise((resolve) => {
        const key = `promptConfirm${Date.now()}`;
        const btn = (
            <Button type="primary" onClick={() => {
                notification.close(key);
                resolve(true);
            }}>
                {confirm}
            </Button>
        );

        notification[type]({
            message, description, btn, key, duration: 0,
            onClose: () => resolve(false)
        });
    });

/**
 * Display a notification prompting for one of multiple options.
 * Resolves to true for overwrite or false for discard/cancel
 *
 * @param type {string} Icon to add to notification
 * @param message {string} Notification header
 * @param description {string} Notification body
 * @param options {{value: any, name: string}[]} Button options
 * @returns {Promise<any>}
 */
export const promptConflict = ({ type = 'open', message, description, overwrite, discard }) =>
    new Promise((resolve) => {
        // const { t } = useTranslation("components");
        const key = `promptConfirm${Date.now()}`;
        const btn = (
            <Space>
                <Button
                    type="primary"
                    onClick={() => {
                        notification.close(key);
                        resolve({action: 'overwrite', value: overwrite});
                    }}
                >
                    {/*{t('overwrite')}*/}
                    Overwrite
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        notification.close(key);
                        resolve({action: 'discard', value: discard});
                    }}
                >
                   {/*{t('discard')}*/}
                    Discard
                </Button>
                <Button onClick={() => {
                    notification.close(key);
                    diffSelect({
                        left: overwrite,
                        right: discard
                    }).then((value) => resolve(value));
                }}>
                    View Details
                </Button>
                <Button onClick={() => {
                    notification.close(key);
                    resolve({action: 'cancel'});
                }}>
                    {/*{t('cancel')}*/}
                    Cancel
                </Button>
            </Space>
        );

        notification[type]({
            message, description, btn, key,
            onClose: () => resolve({action: 'cancel'})
        });
    });


export const diffSelect = async ({ left, right }) =>
    new Promise((resolve) => {
        
        const { t } = useTranslation("components");
        let objectValue = null;
        Modal.info({
            title: t("conflictViewer.label"),
            content: (
                <>
                    <p>Please select a revision</p>
                    <DiffViewer left={{ title: 'Your Revision', obj: left}}
                        right={{ title: 'Latest Revision', obj: right}}
                        onChange={(value) => objectValue = value}
                        defaultSelected="left"
                     />
                </>
            ),
            onOk: () => {resolve({action: 'custom', value: objectValue})},
            onCancel: () => resolve({action: 'cancel'}),
            closable: true,
            okText: 'Save',
            centered: true,
            width: 1000
        });
    })
