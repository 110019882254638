import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';

/**
 * List of locales.
 *
 * When adding a locale:
 * - Ensure that the respective `moment` locale is imported above.
 * - Translate the language name to `common:locales` of all others.
 */
export default [
    {
        "name": "English",
        "code": "en"
    }/*, Currently out of date
    {
        "name": "日本語",
        "code": "ja"
    },
    {
        "name": "Español",
        "code": "es"
    },
    {
        "name": "中文",
        "code": "zh-cn"
    }*/
]