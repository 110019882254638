import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Button, Popconfirm} from "antd";
import {useTranslation} from "react-i18next";

export const DeleteButton = ({onConfirm = console.log, item, placement = 'top', okText = 'Yes', cancelText = 'No', iconOnly = false, disabled, ...props}) => {
    let child = <DeleteOutlined/>;
    const {t} = useTranslation('common')

    if (!iconOnly) {
        child = <Button icon={child} danger type={'primary'} disabled={disabled} {...props}/>;
    }

    return (
        <Popconfirm
            placement={placement}
            title={t('deleteConfirm', {type: item ?? t('types.item')})}
            onConfirm={onConfirm}
            okText={okText}
            cancelText={cancelText}
            disabled={disabled}
        >
            {child}
        </Popconfirm>
    );
};

export default DeleteButton;
